import { Spacing } from '../../molecules/infoItem/InfoItem.types';

export enum ListStyle {
  FILL = 'FILL',
  FIXED = 'FIXED',
}

export type TInfoList = {
  children: JSX.Element | JSX.Element[];
  listStyle?: ListStyle;

  itemSpacing?: Spacing;
};

export type TInfoListStyle = {
  listStyle?: ListStyle;
};
