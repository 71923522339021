import React, { FC, useState } from 'react';

import {
  StyledIframeWrapper,
  StyledIframe,
  StyledOverlay,
} from './Iframe.styled';
import { IframeProps } from './Iframe.types';

export const Iframe: FC<IframeProps> = (props) => {
  const { title, src, thumbnail, icon, trackingAttribute } = props;
  const [showThumbnail, setShowThumbnail] = useState(!!thumbnail);

  return (
    <StyledIframeWrapper
      thumbnail={thumbnail || ''}
      data-tracking={trackingAttribute}
      onClick={() => setShowThumbnail(false)}
      data-testid={`${title}-thumbnail`}
      overlay={!!icon}
    >
      {icon && showThumbnail && <StyledOverlay icon={icon} />}
      {!showThumbnail && (
        <StyledIframe
          data-tracking={`${trackingAttribute}-on-iframe`}
          title={title}
          src={src}
          allowFullScreen
          data-testid={`${title}-iframe`}
          allow={props?.allow}
        />
      )}
    </StyledIframeWrapper>
  );
};
