import styled, { css } from 'styled-components';
import * as Types from './Header.types';

const getHeaderSize = (size: string | undefined) => {
  switch (size) {
    case 'XSMALL':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
      `;
    case 'SMALL':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.L18};
      `;
    case 'MEDIUM':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
        @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
          ${({ theme }) => theme.toolkit.typography.fontSizes.L18};
        }
      `;
    case 'LARGE':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.L18};
        @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
          ${({ theme }) => theme.toolkit.typography.fontSizes.L24};
        }
      `;
    default:
      return '';
  }
};

const getSubtitleSize = (size: string | undefined) => {
  switch (size) {
    case 'XSMALL':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
      `;
    case 'SMALL':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
      `;
    case 'MEDIUM':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S14}
        @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
          ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
        }
      `;
    case 'LARGE':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S14}
        @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
          ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
        }
      `;
    default:
      return '';
  }
};

const getMarginSize = (size: string | undefined) => {
  switch (size) {
    case 'XSMALL':
      return css`
        margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
      `;
    case 'SMALL':
      return css`
        margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
      `;
    case 'MEDIUM':
      return css`
        margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
        @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
          margin-top: ${({ theme }) => theme.toolkit.spacing.S8};
        }
      `;
    case 'LARGE':
      return css`
        margin-top: ${({ theme }) => theme.toolkit.spacing.S8};
      `;
    default:
      return '';
  }
};

const getIllustrationPosition = (size: string | undefined) => {
  switch (size) {
    case 'TOP':
      return css`
        float: left;
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
      `;
    case 'LEFTCENTER':
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        float: left;
        padding-right: ${({ theme }) => theme.toolkit.spacing.M16};
      `;
    case 'LEFTTOP':
      return css`
        float: left;
        padding-right: ${({ theme }) => theme.toolkit.spacing.M16};
      `;
    default:
      return '';
  }
};

export const IllustrationStyle = styled.span<Types.HeaderStyleProps>`
  ${({ iconPosition }) => getIllustrationPosition(iconPosition)}
`;

export const TitleWrapper = styled.div``;

export const Title = styled.p<Types.HeaderStyleProps>`
  font-weight: ${({ theme, headerTitleFontWeight }) => {
    switch (headerTitleFontWeight) {
      case Types.HeaderTitleFontWeight.SEMIBOLD:
        return theme.toolkit.typography.fontWeights.SEMIBOLD;
      case Types.HeaderTitleFontWeight.BOLD:
        return theme.toolkit.typography.fontWeights.BOLD;
      default:
        return theme.toolkit.typography.fontWeights.SEMIBOLD;
    }
  }};
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARKER};
  ${({ headerSizes }) => getHeaderSize(headerSizes)}
`;

export const SubtitleWrapper = styled.div<Types.HeaderStyleProps>`
  ${({ headerSizes }) => getMarginSize(headerSizes)};
`;
export const SubTitle = styled.p<Types.HeaderStyleProps>`
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARKER};
  ${({ headerSizes }) => getSubtitleSize(headerSizes)};
`;

export const Wrapper = styled.div<Types.HeaderStyleProps>`
  display: flex;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === 'TOP' ? 'column' : 'row'};
`;
export const InnerWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
