import React from 'react';

import styled from 'styled-components';

import { safeGet } from '../../../../helpers/utils';

import { DropDownRangeFilter } from '../../atoms/DropDownRange/DropDownRangeFilter';
import { DropDownRangeSelect } from '../../atoms/DropDownRange/DropDownRangeSelect';
import {
  CustomSelect,
  FilterSelectCSS,
  Label,
} from '../../../Styles/SharedFilterStyles';

const Ranges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Text = styled.div`
  font-size: 14px;
  color: #777777;
  margin: 0 12px;
`;

const StyledDropDownRangeSelect = styled(DropDownRangeSelect)<any>`
  ${FilterSelectCSS};
`;

const DropDownRange = (props: any) => (
  <DropDownRangeFilter {...props}>
    {({ getFromProps, getToProps }) => (
      <div>
        {props.showLabel && (
          <Label htmlFor={`${props.id}From`}>{props.displayName}</Label>
        )}
        <Ranges>
          <CustomSelect>
            <StyledDropDownRangeSelect
              {...getFromProps()}
              id={`${props.id}From`}
              className={
                safeGet(props.currentValue, ['from']) !== undefined &&
                safeGet(props.currentValue, ['from']) !== props.values[0].value
                  ? 'filterIsActive'
                  : ''
              }
            />
          </CustomSelect>
          <Text>to</Text>
          <CustomSelect>
            <StyledDropDownRangeSelect
              {...getToProps()}
              id={`${props.id}To`}
              className={
                safeGet(props.currentValue, ['to']) !== undefined &&
                safeGet(props.currentValue, ['to']) !== props.values[0].value
                  ? 'filterIsActive'
                  : ''
              }
            />
          </CustomSelect>
        </Ranges>
      </div>
    )}
  </DropDownRangeFilter>
);

export default DropDownRange;
