import React, { FC } from 'react';

import styled from 'styled-components';

import { Breadcrumbs } from '../../../types';
import Link from 'next/link';

const css = require('styled-components').css;

type BreadcrumbsProps = {
  breadcrumbs?: Breadcrumbs;
  BASE_URL: string;
};

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;

const crumbCSS = css`
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;

  @media only screen and (min-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
  }

  &::after {
    content: '/';
    padding: 0;
    margin: 0 8px;
  }
`;

const BreadcrumbTitle = styled.p`
  ${crumbCSS}
`;

const LastCrumb = styled.span`
  ${crumbCSS}
  &:last-of-type {
    color: #717171;
    &::after {
      display: none;
    }
  }
`;

export const BreadcrumbList: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  if (!breadcrumbs) return null;
  const listLength = breadcrumbs.length;

  return (
    <>
      <Nav data-testid="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => {
          if (listLength - 1 === index) {
            return (
              <LastCrumb key={breadcrumb.url}>
                {breadcrumb.displayValue}
              </LastCrumb>
            );
          }

          return (
            <Link href={breadcrumb.url} passHref key={breadcrumb.url}>
              <BreadcrumbTitle>{breadcrumb.displayValue}</BreadcrumbTitle>
            </Link>
          );
        })}
      </Nav>
    </>
  );
};
