import { Component, ReactNode } from 'react';

type DropDownRangeFilterOption = {
  valueType: string;
  displayName: string;
  value: string;
};

type DropDownRangeFilterProps = {
  values: [any];
  displayName: string;
  children: (stateAndHelpers: {
    onChange: any;
    getFromProps: any;
    getToProps: any;
  }) => ReactNode;
  searchQueryGroup: string;
  name: string;
  currentValue?: { from?: string; to?: string };
  filterType: { name: string };
  noLabelInDisplayText?: boolean;
  onChange: (state: any) => void;
  getDisplayText?: (value: string) => void;
};

type FilterChange = {
  filterType: string;
  name: string;
  rangeSide: string;
  searchQueryGroup: string;
  values: [string];
  displayName: string;
};

interface DropDownRangeFilterState {
  searchQueryGroup: string;
  filterType: string;
  name: string;
  from: string;
  to: string;
  fromValues: DropDownRangeFilterOption[];
  toValues: DropDownRangeFilterOption[];
  displayFrom: string | undefined;
  displayTo: string | undefined;
  currentValue: any;
}

class DropDownRangeFilter extends Component<
  DropDownRangeFilterProps,
  DropDownRangeFilterState
> {
  getStateForCurrentValue() {
    const from = this.getInitialValue('from');
    const to = this.getInitialValue('to');

    return {
      from,
      to,
      currentValue: { from, to },
      fromValues: this.getInitialValues('from'),
      toValues: this.getInitialValues('to'),
      displayFrom: this.getInitialDisplayValue('from'),
      displayTo: this.getInitialDisplayValue('to'),
    };
  }

  updateParentDisplayText() {
    if (this.props.getDisplayText) {
      const displayText = this.buildDisplayText();
      this.props.getDisplayText(displayText);
    }
  }

  constructor(props: DropDownRangeFilterProps) {
    super(props);
    this.state = {
      ...this.getStateForCurrentValue(),
      searchQueryGroup: this.props.searchQueryGroup,
      filterType: this.props.filterType.name,
      name: this.props.name,
    };
    this.updateParentDisplayText();
  }

  componentDidMount() {
    const { from, to } = this.state;
    this.balanceValuesBasedOnUsersSelection({ from, to });
  }

  componentDidUpdate(prevProps: DropDownRangeFilterProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState(this.getStateForCurrentValue(), () => {
        this.updateParentDisplayText();
        const { from, to } = this.state;
        this.balanceValuesBasedOnUsersSelection({ from, to });
      });
    }
  }

  private getInitialValue = (side: 'from' | 'to'): string => {
    const { currentValue } = this.props;
    if (side === 'from') {
      return currentValue?.from ?? '';
    }
    return currentValue?.to ?? '';
  };

  private getInitialValues = (side: 'from' | 'to'): any => {
    const { values } = this.props;
    const FallbackValue = {
      valueType: 'FilterStringValue',
      displayName: '----',
      value: '',
    };
    if (side === 'from') {
      return values?.length && values[0].from
        ? values[0].from
        : [FallbackValue];
    }
    return values?.length && values[0].to ? values[0].to : [FallbackValue];
  };

  public getInitialDisplayValue(side: 'from' | 'to'): string | undefined {
    const { values } = this.props;
    const from = this.getInitialValue('from');
    const to = this.getInitialValue('to');
    const isValues = Boolean(values?.length && values[0]);
    const isToValue = Boolean(to);
    const isFromValue = Boolean(from);

    if (typeof this.props.getDisplayText === 'function') {
      if (side === 'from' && isFromValue && isValues) {
        const selectedItem: DropDownRangeFilterOption[] = values[0].from.filter(
          (item: DropDownRangeFilterOption) => item.value === from,
        );
        return selectedItem[0]?.displayName;
      }

      if (side === 'to' && isToValue && isValues) {
        const selectedItem: DropDownRangeFilterOption[] = values[0].to.filter(
          (item: DropDownRangeFilterOption) => item.value === to,
        );
        return selectedItem[0]?.displayName;
      }
    }
    return undefined;
  }

  private balanceValuesBasedOnUsersSelection(
    updatedValues: { from?: string; to?: string },
    rangeSide?: string,
  ): void {
    const { from, to } = updatedValues;
    const values = this.props.values[0];
    if (from) {
      const copyOfToValues = values.to.slice(1);
      const copyOfDefault = values.to.slice(0, 1);
      const newToValues = copyOfToValues.filter(
        (filter: DropDownRangeFilterOption) =>
          Number(filter.value) >= Number(from),
      );
      newToValues.unshift(copyOfDefault[0]);
      this.setState({ toValues: newToValues });
    } else if (rangeSide === 'from') {
      this.setState({ toValues: values.to });
    }

    if (to) {
      const copyOfFromValues = values.from.slice(1);
      const copyOfDefault = values.from.slice(0, 1);
      const newFromValues = copyOfFromValues.filter(
        (filter: DropDownRangeFilterOption) =>
          Number(filter.value) <= Number(to),
      );
      newFromValues.unshift(copyOfDefault[0]);
      this.setState({ fromValues: newFromValues });
    } else if (rangeSide === 'to') {
      this.setState({ fromValues: values.from });
    }
  }

  private buildDisplayText(): string {
    const { displayFrom, displayTo } = this.state;
    const label = this.props.displayName;
    const { noLabelInDisplayText } = this.props;
    const displayLabel = noLabelInDisplayText ? '' : ` ${label}`;

    if (displayFrom && displayTo) {
      return `${displayFrom} - ${displayTo}${displayLabel}`;
    }

    if (displayFrom) {
      return `${displayFrom}${displayLabel} Min`;
    }

    if (displayTo) {
      return `${displayTo}${displayLabel} Max`;
    }

    return `${label}`;
  }

  onUpdate = (filterChange: FilterChange): void => {
    const { displayName } = filterChange;
    const { fromValues, toValues } = this.state;
    const isFrom = filterChange.rangeSide === 'from';
    let updatedValues = {};

    if (isFrom) {
      const isDefault = Boolean(fromValues[0].displayName === displayName);
      updatedValues = {
        from: filterChange.values[0],
        displayFrom: isDefault ? '' : displayName,
      };
    } else {
      const isDefault = Boolean(toValues[0].displayName === displayName);
      updatedValues = {
        to: filterChange.values[0],
        displayTo: isDefault ? '' : displayName,
      };
    }

    this.balanceValuesBasedOnUsersSelection(
      updatedValues,
      filterChange.rangeSide,
    );

    this.setState(
      (currentState: DropDownRangeFilterState) => {
        return {
          ...currentState,
          ...updatedValues,
          currentValue: { ...currentState.currentValue, ...updatedValues },
        };
      },
      () => {
        if (typeof this.props.getDisplayText === 'function') {
          const displayText = this.buildDisplayText();
          this.props.getDisplayText(displayText);
        }
        const { ...updates } = this.state;
        return this.props.onChange(updates);
      },
    );
  };

  getFromProps = (props: any) => ({
    name: `${this.props.name}From`,
    values: this.state.fromValues,
    onChange: this.onUpdate,
    rangeSide: 'from',
    searchQueryGroup: this.props.searchQueryGroup,
    currentValue: {
      values: [this.props?.currentValue?.from],
    },
    'aria-label': `${this.props.name}: From`,
    ...props,
  });

  getToProps = (props: any) => ({
    name: `${this.props.name}To`,
    values: this.state.toValues,
    onChange: this.onUpdate,
    rangeSide: 'to',
    searchQueryGroup: this.props.searchQueryGroup,
    currentValue: {
      values: [this.props?.currentValue?.to],
    },
    'aria-label': `${this.props.name}: To`,
    ...props,
  });

  getStateAndHelpers() {
    return {
      onChange: this.onUpdate,
      getFromProps: this.getFromProps,
      getToProps: this.getToProps,
    };
  }

  render() {
    return this.props.children(this.getStateAndHelpers());
  }
}

export { DropDownRangeFilter };
