import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { DropDownRangeFilter } from '../../atoms/DropDownRange/DropDownRangeFilter';
import { DropDownRangeSelect } from '../../atoms/DropDownRange/DropDownRangeSelect';
import {
  BlockyFakeSelect,
  BlockyMenuCSS,
  BlockySelect,
  CustomSelect,
  DoneButton,
  FilterSelectCSS,
  Label,
} from '../../../Styles/SharedFilterStyles';

const Ranges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Text = styled.div`
  font-size: 14px;
  color: #777777;
  margin: 0 12px;
`;

const StyledDropDownRangeSelect = styled(DropDownRangeSelect)<any>`
  ${FilterSelectCSS};
`;

const PopOutWrapper = styled.div`
  @media only print, screen and (min-width: 704px) {
    position: relative;
  }

  &.hasOpenMenu {
    border-bottom-left-radius: 0 !important;
    border-bottom: 0 !important;
  }

  select {
    min-width: 105px;
  }
`;

const Menu = styled.div`
  ${BlockyMenuCSS}
  top: auto;
  ${(props: { showMenu?: boolean }) =>
    props.showMenu &&
    `
      display: block;
    `};
`;

const BlockyPopOutRange = (props: any) => {
  const setTextInputRef = useRef(null);
  const popOutWrapper = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [displayText, setDisplayText] = useState(props.displayName);

  const handleClickOutside = (event: any) => {
    if ((popOutWrapper as any)?.current?.contains(event.target)) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = (event: any) => {
    event.preventDefault();
    setShowMenu(!showMenu);
  };

  const updateDisplayText = (value: string) => {
    setDisplayText(value);
  };

  const { displayName, showLabel, id } = props;
  return (
    <PopOutWrapper
      ref={popOutWrapper}
      className={showMenu ? 'hasOpenMenu' : ''}
    >
      <BlockySelect>
        <BlockyFakeSelect onClick={toggleMenu} data-testid={id}>
          {displayText}
        </BlockyFakeSelect>
      </BlockySelect>

      <div ref={setTextInputRef}>
        <Menu
          showMenu={showMenu}
          data-testid={`${id}Menu`}
          className={showMenu ? 'isOpen' : ''}
        >
          <DropDownRangeFilter
            {...(props as any)}
            getDisplayText={updateDisplayText}
          >
            {({ getFromProps, getToProps }) => (
              <div>
                {showLabel && (
                  <Label htmlFor={`${id}From`}>{displayName}</Label>
                )}
                <Ranges>
                  <CustomSelect>
                    <StyledDropDownRangeSelect
                      {...getFromProps()}
                      id={`${id}From`}
                    />
                  </CustomSelect>
                  <Text>To</Text>
                  <CustomSelect>
                    <StyledDropDownRangeSelect
                      {...getToProps()}
                      id={`${id}To`}
                    />
                  </CustomSelect>
                </Ranges>
              </div>
            )}
          </DropDownRangeFilter>
          <DoneButton onClick={toggleMenu} />
        </Menu>
      </div>
    </PopOutWrapper>
  );
};

export default BlockyPopOutRange;
