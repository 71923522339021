import Link from 'next/link';
import * as Styled from './Header.styled';
import { Burger } from './burger/Burger';
import { Navigation } from './navigation/Navigation';
import { useHeader } from './Header.hook';
import { Logo } from '../../atoms';
import { THeader } from './Header.types';

export function Header({
  data,
  bgColor = 'primary',
  logoHref,
  children,
}: THeader) {
  const { showNav, toggleNavigation } = useHeader();
  return (
    <Styled.HeaderWrapper aria-label="Header" bgColor={bgColor}>
      <Styled.Logo>
        <Link href={logoHref} passHref>
          <Logo name="LOGO_AGENT_SYSTEM" />
          <span className="hide-accessibility">
            Click to go back to the homepage
          </span>
        </Link>
      </Styled.Logo>

      <Styled.BurgerWrapper>
        <Burger initialState={showNav} callback={toggleNavigation} />
      </Styled.BurgerWrapper>

      <Styled.MainNav
        bgColor={bgColor}
        aria-label="Main"
        id="Main__Navigation"
        className={showNav ? 'show' : 'hide'}
      >
        <Styled.InnerWrapper>
          <Navigation
            mainNav={data.mainNav}
            utilityNav={data?.utilityNav}
            children={children}
          />
        </Styled.InnerWrapper>
      </Styled.MainNav>
    </Styled.HeaderWrapper>
  );
}
