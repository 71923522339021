import React, { Component } from 'react';

type Option = {
  value: string;
  displayName: string;
  type: string;
};

type OnChangeProps = {
  name?: string;
  from: string;
  to: string;
  searchQueryGroup?: string;
  filterType: string;
  currentValue: { from?: string; to?: string };
};

type SingleSelectRangeFilterState = {
  value: string;
  from: string;
  to: string;
};

export type SingleSelectRangeFilterProps = {
  name?: string;
  displayName?: string;
  values: Option[];
  id?: string;
  onChange: ({ name, searchQueryGroup, filterType }: OnChangeProps) => void;
  className?: string;
  disabled?: boolean;
  currentValue?: { from?: string; to?: string; values?: any[] };
  searchQueryGroup?: string;
};

class SingleSelectRangeFilter extends Component<
  SingleSelectRangeFilterProps,
  SingleSelectRangeFilterState
> {
  getStateForCurrentValue() {
    const { currentValue, values } = this.props;
    const firstItemInList = values.length ? values[0].value : '';
    const from = this.getInitialValue('from');
    const to = this.getInitialValue('to');
    let initValue = '';

    if (currentValue && (from || to)) {
      initValue = from || to;
    } else {
      initValue = firstItemInList;
    }

    return {
      from,
      to,
      value: initValue,
    };
  }

  constructor(props: SingleSelectRangeFilterProps) {
    super(props);
    this.state = this.getStateForCurrentValue();
  }

  componentDidUpdate(prevProps: SingleSelectRangeFilterProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState(this.getStateForCurrentValue());
    }
  }

  private getInitialValue = (side: 'from' | 'to'): string => {
    const { currentValue } = this.props;
    if (side === 'from') {
      return currentValue?.from ?? '';
    }
    return currentValue?.to ?? '';
  };

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { onChange, searchQueryGroup, name } = this.props;
    const selectedItem = this.props.values.filter(
      (item: any) => item.value === value,
    );
    const type = selectedItem[0].type;
    let from = '';
    let to = '';
    const updatedValue: { from?: string; to?: string } = {};

    if (type === 'MIN') {
      from = value;
      updatedValue.from = value;
    } else {
      to = value;
      updatedValue.to = value;
    }

    onChange({
      name,
      searchQueryGroup,
      from,
      to,
      currentValue: updatedValue,
      filterType: 'SingleSelectRange',
    });
    this.setState({ value, from, to });
  };

  render() {
    const { name, className, disabled = false, values, id } = this.props;
    return (
      <select
        name={name}
        className={className}
        disabled={disabled}
        value={this.state.value}
        onChange={this.handleChange}
        id={id ?? name}
        data-testid={name}
        aria-label={name}
      >
        {values.map(({ value, displayName }: Option) => (
          <option key={`${name}-${value}`} value={value}>
            {displayName}
          </option>
        ))}
      </select>
    );
  }
}

export { SingleSelectRangeFilter };
