import { StyledError } from './Error.styled';
import { TError } from './Error.types';

export function Error({ children, id, ...rest }: TError) {
  return (
    <StyledError id={id} role="alert" {...rest}>
      {children}
    </StyledError>
  );
}

export default Error;
