import React from 'react';

import { AutocompleteAPI } from '../../api';
import VariableDropDownRange from './components/organisms/VariableDropDownRange/VariableDropDownRange';
import VariableListMultiSelect from './components/organisms/VariableListMultiSelect/VariableListMultiSelect';
import VariableSingleSelect from './components/organisms/VariableSingleSelect/VariableSingleSelect';
import VariableSingleSelectRange from './components/organisms/VariableSingleSelectRange/VariableSingleSelectRange';
import { AutocompleteConnectionHelper } from '../helpers/AutocompleteConnectionHelper';
import { CountyAreaFilter } from './components/organisms/CountyAreaFilter/CountyAreaFilter';
import { TermsFilter } from './components/organisms/TermsFilter/TermsFilter';

import { UIFilters } from './FilterTypes';

export const filters: UIFilters = {
  ListSingleSelect: (props: any) => <VariableSingleSelect {...props} />,
  ListMultiSelect: (props: any) => <VariableListMultiSelect {...props} />,
  DropDownRange: (props: any) => <VariableDropDownRange {...props} />,
  SingleSelectRange: (props: any) => <VariableSingleSelectRange {...props} />,
  TextInput: (props: any) => <TermsFilter {...props} />,
  LocationInputBox: (props: any) => (
    <AutocompleteConnectionHelper
      apiCall={AutocompleteAPI.suggestions}
      key={props.key}
      path={props.options.currentPath}
      baseURL={props.options.baseURL}
    >
      {({ getAutocompleteProps }: any) => (
        <CountyAreaFilter {...getAutocompleteProps(props)} />
      )}
    </AutocompleteConnectionHelper>
  ),
};
