import styled from 'styled-components';

export const RangeSelectWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const SelectContainer = styled.div`
  flex: 1;
`;
