import styled, { css, DefaultTheme } from 'styled-components';
import { ContainerStyledProps } from './Container.types';
import {
  BreakpointsOptional,
  forEachBreakpoint,
  TSpacingValue,
} from '@dist-property-frontend/configs';

/**
 * Resolves the appropriate CSS value for a given property, breakpoint, and theme.
 * Handles padding specifically by converting spacing tokens into theme-defined values.
 *
 * @param propValue - The value of the property for the given breakpoint (e.g., 'S16', '1280px').
 * @param propertyName - The CSS property being applied (e.g., 'padding', 'max-width').
 * @param theme - The theme object containing spacing and breakpoint definitions.
 * @param defaultValue - The fallback value if no specific value is provided for the property.
 * @returns The resolved CSS value, or undefined if no value is found.
 */
const resolveValue = (
  propValue: string | TSpacingValue | undefined,
  propertyName: string,
  theme: DefaultTheme,
  defaultValue?: string,
): string | undefined => {
  if (!propValue) return defaultValue;

  // For 'padding', use theme spacing tokens if available.
  if (propertyName === 'padding') {
    return theme.toolkit.spacing[propValue as TSpacingValue] || propValue;
  }

  // For other properties, return the value directly.
  return propValue;
};

/**
 * Generates responsive CSS styles based on provided breakpoints and properties.
 * Iterates through breakpoints and applies values for the given property and theme.
 *
 * @param prop - An object defining values for each breakpoint (e.g., { xs: 'S16', lg: 'S32' }).
 * @param propertyName - The CSS property to apply (e.g., 'max-width', 'padding').
 * @param theme - The theme object containing breakpoint and spacing definitions.
 * @param defaultValue - A fallback value if no specific value is provided for the property.
 * @returns CSS styles with media queries for the specified breakpoints.
 */
const getResponsiveStyles = (
  prop: BreakpointsOptional<string | TSpacingValue> | undefined,
  propertyName: string,
  theme: DefaultTheme,
  defaultValue?: string,
) => {
  let styles = '';

  // Iterate through breakpoints and generate styles for each.
  forEachBreakpoint((breakpoint) => {
    const value = prop
      ? resolveValue(prop[breakpoint], propertyName, theme, defaultValue)
      : defaultValue;

    if (value) {
      styles += `
        @media (min-width: ${theme.toolkit.breakpoints[breakpoint]}px) {
          ${propertyName}: ${propertyName === 'padding' ? `0 ${value}` : value};
        }
      `;
    }
  });

  return css`
    ${styles}
  `;
};

/**
 * Styled container component with customizable max-width and padding.
 * Provides responsive styles based on the theme and breakpoints.
 *
 * @param backgroundColor - Background color of the container (default: '#FFFFFF').
 * @param maxWidth - Optional max-width values for different breakpoints.
 * @param paddingX - Optional horizontal padding values for different breakpoints.
 */
export const Container = styled.div<ContainerStyledProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#FFFFFF'};
  display: block;
  margin: 0 auto;

  ${({ maxWidth, theme }) =>
    getResponsiveStyles(maxWidth, 'max-width', theme, '1280px')};

  /* Responsive styles for horizontal padding */
  ${({ paddingX, theme }) => getResponsiveStyles(paddingX, 'padding', theme)}
`;

export default Container;
