import React from 'react';

import styled from 'styled-components';

import { safeGet } from '../../../../helpers';

import { SingleSelectRangeFilter } from '../../atoms/SingleSelectRange/SingleSelectRange';
import {
  CustomSelect,
  FilterSelectCSS,
  Label,
} from '../../../Styles/SharedFilterStyles';

const StyledSingleSelectFilter = styled(SingleSelectRangeFilter)<any>`
  ${FilterSelectCSS};
`;

const DefaultSingleSelectFilter = (props: any) => (
  <div>
    {props.showLabel && <Label htmlFor={props.id}>{props.displayName}</Label>}
    <CustomSelect>
      <StyledSingleSelectFilter
        {...props}
        className={
          (safeGet(props.currentValue, ['from']) !== undefined &&
            safeGet(props.currentValue, ['from']) !== props.values[0].value) ||
          (safeGet(props.currentValue, ['to']) !== undefined &&
            safeGet(props.currentValue, ['to']) !== props.values[0].value)
            ? 'filterIsActive'
            : ''
        }
      />
    </CustomSelect>
  </div>
);

export default DefaultSingleSelectFilter;
