import React from 'react';

import { CloseIcon } from '@dsch/icons';
import styled from 'styled-components';

type TagValueProps = {
  className?: string;
  children: { displayName: string };
  onRemove: (item: any) => void;
};

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 12px;
  background-color: transparent;
  color: #4170c4;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  cursor: default;
  border: 1px solid #4170c4;
  border-radius: 4px;

  @media only screen and (min-width: 640px) {
    min-height: 29px;
    font-size: 14px;
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  margin-left: 5px;
  height: 17px;
  width: 17px;
  color: #4170c4;
  cursor: pointer;
`;

class TagValue extends React.Component<TagValueProps> {
  onRemove = (event: React.MouseEvent) => {
    const { onRemove } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (onRemove) {
      onRemove({ ...this.props.children });
    }
  };
  render() {
    const { displayName } = this.props.children;
    return (
      <Wrapper data-testid="tag" className={this.props.className}>
        {displayName}{' '}
        <CloseIconStyled
          onClick={this.onRemove}
          role="button"
          aria-label={`delete ${displayName} tag`}
        />
      </Wrapper>
    );
  }
}

export default TagValue;
