import { useState } from 'react';

export const useQuickFilters = () => {
  const [openFilters, setOpenFilters] = useState<{ [key: string]: boolean }>(
    {},
  );
  const toggleFilter = (key: string) => {
    setOpenFilters((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const closeFilter = (key: string) => {
    setOpenFilters((prev) => ({
      ...prev,
      [key]: false,
    }));
  };

  return {
    openFilters,
    toggleFilter,
    closeFilter,
  };
};
