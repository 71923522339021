import styled from 'styled-components';

export const Wrapper = styled.ul`
  padding: 0 ${({ theme }) => theme.toolkit.spacing.M32};
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    padding: 0;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.toolkit.spacing.M16};
    @media ${({ theme }) => theme.toolkit.mediaQuery.xlarge} {
      gap: ${({ theme }) => theme.toolkit.spacing.M32};
    }
  }
`;

export const MainNav = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    flex-direction: row;
  }
`;

export const UtilityNav = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    flex-direction: row;
  }
`;
