export enum TextFontWeight {
  REGULAR = 'REGULAR',
  SEMIBOLD = 'SEMIBOLD',
  BOLD = 'BOLD',
}

export enum TextSize {
  S10 = 'S10',
  S12 = 'S12',
  S14 = 'S14',
  M14 = 'M14',
  M16 = 'M16',
  L16 = 'L16',
  L18 = 'L18',
  L20 = 'L20',
  L24 = 'L24',
  XL28 = 'XL28',
  XL36 = 'XL36',
  XL44 = 'XL44',
}

export interface Props<T extends React.ElementType> {
  as?: T;
  children: React.ReactNode;
  fontWeight?: TextFontWeight;
  lineClamp?: number;
  size?: TextSize;
  textColor?: string;
}

export type TextProps<T extends React.ElementType> = Props<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>;

export const VariantMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
  th: 'th',
} as const;

export type StyleProps = {
  fontWeight?: string;
  lineClamp?: number;
  size?: string;
  textColor?: string;
};
