import React, { Component } from 'react';

import styled from 'styled-components';

import { FauxCheckbox } from '../../atoms/FauxCheckbox';
import {
  ListMultiSelectFilter,
  ListSelectValue,
  ListMultiSelectProps,
} from '../../atoms/ListMultiSelect/ListMultiSelect';
import {
  CustomSelect,
  FakeSelect,
  MenuCSS,
} from '../../../Styles/SharedFilterStyles';

const List = styled.ul`
  list-style-type: none;
  padding-top: 8px;
`;

const ListItem = styled.li`
  height: 34px;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;

const Menu = styled.div`
  min-width: 200px;
  ${MenuCSS};
  top: calc(100% - 8px);
  ${(props: { showMenu?: boolean }) =>
    props.showMenu &&
    `
      display: block;
    `};
`;

class PopOutListMultiSelect extends Component<ListMultiSelectProps> {
  render() {
    const { values, displayName } = this.props;
    return (
      <ListMultiSelectFilter {...this.props}>
        {({
          getItemProps,
          getInputProps,
          getMenuProps,
          getToggleButtonProps,
          getLabelText,
          selectedItems,
          isOpen,
        }: any) => (
          <>
            {displayName && (
              <StyledCustomSelect>
                <FakeSelect
                  data-testid="multi-select-list-button"
                  {...getToggleButtonProps()}
                >
                  {getLabelText(
                    values.filter(
                      (item: ListSelectValue) =>
                        selectedItems.filter(
                          (selectedItem: ListSelectValue) =>
                            item.value === selectedItem.value,
                        ).length > 0,
                    ),
                    displayName,
                  )}
                </FakeSelect>
              </StyledCustomSelect>
            )}
            <Menu showMenu={isOpen} data-testid="multi-select-list-wrapper">
              <List {...getMenuProps()}>
                {values.map((item: ListSelectValue, index: number) => {
                  const itemIsChecked =
                    selectedItems.filter(
                      (selectedItem: ListSelectValue) =>
                        item.value === selectedItem.value,
                    ).length > 0;
                  return (
                    <ListItem
                      {...getItemProps({
                        index,
                        item,
                        id: `multi-select-list-item-${item.value}`,
                        key: index,
                      })}
                      data-testid={`multi-select-list-item-${item.value}`}
                      aria-selected={itemIsChecked}
                      key={`${index}-${item.value}`}
                    >
                      <FauxCheckbox
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                        {...getInputProps({
                          id: `multi-select-item-${item.value}`,
                          name: `checkbox-item${item.value}`,
                          value: item.value,
                          checked: itemIsChecked,
                          checkboxText: item.displayName,
                        })}
                        data-testid={`multi-select-item-${item.value}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Menu>
          </>
        )}
      </ListMultiSelectFilter>
    );
  }
}

export default PopOutListMultiSelect;
