import * as Types from './Header.types';
import * as Styled from './Header.styled';
import { Illustration } from '../../atoms/illustrations/Illustration';

export const Header = ({
  children,
  headerSizes = Types.HeaderSizes.MEDIUM,
  headerTitleFontWeight,
  iconPosition = Types.IconPosition.TOP,
  subtitle = true,
  setIcon = true,
  text,
  subtitleText,
  illustrationName = 'PHONE_INCREASE',
  height = 40,
}: Types.HeaderProps) => {
  return (
    <Styled.Wrapper iconPosition={iconPosition}>
      {setIcon && (
        <Styled.IllustrationStyle iconPosition={iconPosition}>
          <Illustration
            illustration={illustrationName}
            height={height}
            data-testid="illustration"
          ></Illustration>
          {children}
        </Styled.IllustrationStyle>
      )}
      <Styled.InnerWrapper>
        <Styled.TitleWrapper>
          <Styled.Title
            headerSizes={headerSizes}
            headerTitleFontWeight={headerTitleFontWeight}
          >
            {text}
          </Styled.Title>
        </Styled.TitleWrapper>
        {subtitle && (
          <Styled.SubtitleWrapper
            headerSizes={headerSizes}
            data-testid="subtitle-wrapper"
          >
            <Styled.SubTitle headerSizes={headerSizes}>
              {subtitleText}
            </Styled.SubTitle>
          </Styled.SubtitleWrapper>
        )}
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

export default Header;
