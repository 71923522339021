import * as Styled from './Dropzone.styled';
import { Space, Text, TextFontWeight, TextSize } from '../../atoms';
import { DropzoneProps } from './Dropzone.types';

export const Dropzone = ({
  uploadText,
  openFileBrowser,
  onKeyDown,
  onFileDrop,
  handleFileInput,
  inputRef,
  acceptedFileTypes,
  hideDropzone,
}: DropzoneProps) => {
  const handleDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
  };
  return (
    <>
      <input
        id="fileInput"
        ref={inputRef}
        type="file"
        multiple={true}
        onChange={handleFileInput}
        hidden={true}
        accept={acceptedFileTypes.join(',')}
        data-testid="dropzone-Input"
      />
      {!hideDropzone && (
        <>
          <Styled.Wrapper
            data-testid="dropzone-wrapper"
            tabIndex={0}
            onClick={openFileBrowser}
            onKeyDown={onKeyDown}
            onDrop={onFileDrop}
            onDragOver={handleDragOver}
          >
            <Styled.Container>
              <Styled.BaseIcon height={40} illustration="UPLOAD" />
              <Space space={{ xl: 'M16', sm: 'S8' }} />
              <Text size={TextSize.M16} fontWeight={TextFontWeight.SEMIBOLD}>
                {uploadText}
              </Text>
              <Space space={'S8'} />
              <Text size={TextSize.S14}>
                Drag and drop or{' '}
                <Styled.DummyLink>browse files</Styled.DummyLink>
              </Text>
              <Space space={{ xl: 'M16', sm: 'S8' }} />
              <Text size={TextSize.S12}>Support .jpg and .png</Text>
            </Styled.Container>
          </Styled.Wrapper>
          <Space space={{ xl: 'M16' }} />
        </>
      )}
    </>
  );
};
