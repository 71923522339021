import React, { Component } from 'react';

import styled from 'styled-components';

import { MultiSelectItem } from '../../atoms/MultiSelectItem';

import {
  ListMultiSelectFilter,
  ListSelectValue,
  ListMultiSelectProps,
} from '../../atoms/ListMultiSelect/ListMultiSelect';
import { Label } from '../../../Styles/SharedFilterStyles';

const List = styled.ul`
  list-style-type: none;
  padding-top: 8px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 12px;
`;

const ListItem = styled.li`
  flex: 1 1 48%;
`;

const BasicLabel = styled(Label)`
  display: block;
`;

class DefaultListMultiSelect extends Component<ListMultiSelectProps> {
  render() {
    const { values, displayName, showLabel = true } = this.props;
    return (
      <ListMultiSelectFilter {...this.props}>
        {({
          getItemProps,
          getInputProps,
          getMenuProps,
          selectedItems,
        }: any) => (
          <>
            {showLabel && displayName && <BasicLabel>{displayName}</BasicLabel>}
            <div data-testid="multi-select-list-wrapper">
              <List {...getMenuProps()}>
                {values.map((item: ListSelectValue, index: number) => {
                  const itemIsChecked =
                    selectedItems.filter(
                      (selectedItem: ListSelectValue) =>
                        item.value === selectedItem.value,
                    ).length > 0;
                  return (
                    <ListItem
                      {...getItemProps({
                        index,
                        item,
                        id: `multi-select-list-item-${item.value}`,
                        key: index,
                      })}
                      data-testid={`multi-select-list-item-${item.value}`}
                      aria-selected={itemIsChecked}
                      key={`${index}-${item.value}`}
                    >
                      <MultiSelectItem
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                        {...getInputProps({
                          id: `multi-select-item-${item.value}`,
                          name: `checkbox-item${item.value}`,
                          value: item.value,
                          checked: itemIsChecked,
                          checkboxText: item.displayName,
                        })}
                        data-testid={`multi-select-item-${item.value}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </>
        )}
      </ListMultiSelectFilter>
    );
  }
}

export default DefaultListMultiSelect;
