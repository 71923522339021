import { ReactNode } from 'react';

type ListSelectValue = {
  valueType: string;
  value: string;
  displayName: string;
};

export type FilterToBuild = {
  id: string;
  name: string;
  displayName: string;
  variant: string;
  searchQueryGroup: string;
  filterType: {
    id: string;
    name: string;
  };
  currentValue: [string];
};

export type ListSingleSelect = FilterToBuild & { values: ListSelectValue[] };
export type ListMultiSelect = FilterToBuild & { values: ListSelectValue[] };
export type RangesSelect = FilterToBuild & { from: string; to: string };
export type PagingSelect = FilterToBuild & { from: string };

export type SearchFilters = (
  | ListMultiSelect
  | ListSingleSelect
  | RangesSelect
  | PagingSelect
)[];

export type SearchFiltersResponse = {
  showByDefault: SearchFilters;
  hideByDefault: SearchFilters;
};

export enum SearchFilterTypes {
  ListSingleSelect = 'ListSingleSelect',
  ListMultiSelect = 'ListMultiSelect',
  DropDownRange = 'DropDownRange',
  Pagination = 'Pagination',
  SingleSelectRange = 'SingleSelectRange',
  CountyArea = 'CountyArea',
  TextInput = 'TextInput',
  MapView = 'MapView',
  GroupedStepper = 'GroupedStepper',
}

export interface BaseFilterObject {
  filterType: SearchFilterTypes;
  searchQueryGroup: string;
}

export interface PagingFilterObject extends BaseFilterObject {
  from: number;
  startTime: number;
  pageSize: number;
}

export interface ListSingleSelectFilterObject extends BaseFilterObject {
  name: string;
  values: [string];
}

export interface ListMultiSelectFilterObject extends BaseFilterObject {
  name: string;
  currentValue: { values: [string] };
}

export interface DropDownRangeFilterObject extends BaseFilterObject {
  name: string;
  from: string;
  to: string;
}

// TODO: [Filters] Try link these to SearchFilterTypes
export type UIFilters = {
  ListSingleSelect: (props: any) => JSX.Element;
  ListMultiSelect: (props: any) => JSX.Element;
  DropDownRange: (props: any) => JSX.Element;
  [key: string]: any;
};

export type TermsQuery = {
  name: string;
  currentValue?: any;
  displayName?: string;
  searchQueryGroup?: string;
  onChange?: any;
  filterType?: string;
};

export type ListMultiSelectQuery = {
  name: string;
  values: string[];
  currentValue?: { values: string[] };
  displayName?: string;
  searchQueryGroup?: string;
  onChange?: any;
  filterType?: string;
};

export type FilterQuery = {
  searchQueryGroup?: string;
  name: string;
  values: string[];
  currentValue?: any;
  displayName?: string;
};

export type FilterSearchQuery = {
  searchQueryGroup: string;
  name: string;
  values: string[];
  currentValue?: any;
  displayName?: string;
};

export type RangeQuery = {
  searchQueryGroup?: string;
  name: string;
  from: string;
  to: string;
  currentValue?: any;
  displayName?: string;
};

export type PagingQuery = {
  searchQueryGroup: string;
  name: string;
  from: string;
  currentValue?: any;
  displayName?: string;
  pageSize: string;
};

export type SearchRequest = {
  [searchQueryGroup: string]: any;
  ranges: RangeQuery[];
  terms: string;
  filters: FilterQuery[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  paging: PagingQuery | {};
  geoFilter: any;
  andFilters: ListMultiSelectQuery[];
  isPledge?: string;
};

export type FilterRequestAPI = (
  | FilterQuery
  | RangeQuery
  | PagingQuery
  | ListMultiSelectQuery
  | TermsQuery
) & {
  searchQueryGroup: string;
};

export enum ElementTypes {
  checkbox = 'checkbox',
  checkboxGroup = 'checkboxGroup',
  DropDownRange = 'DropDownRange',
  GroupedStepper = 'GroupedStepper',
  input = 'input',
  ListMultiSelect = 'ListMultiSelect',
  ListSingleSelect = 'ListSingleSelect',
  select = 'select',
  Stepper = 'Stepper',
  TextInput = 'TextInput',
  SubHeading = 'SubHeading',
  TextInputWithDropdown = 'TextInputWithDropdown',
  TextArea = 'TextArea',
  DatePicker = 'DatePicker',
  RadioButtonGroupInput = 'RadioButtonGroupInput',
  Recaptcha = 'Recaptcha',
  AutocompleteInputType = 'AutocompleteInputType',
  FillerContent = 'FillerContent',
  Hidden = 'Hidden',
}

export enum InputTypes {
  text = 'text',
  email = 'email',
  number = 'number',
  password = 'password',
  search = 'search',
  tel = 'tel',
  url = 'url',
  currency = 'currency',
  hidden = 'hidden',
}

export interface TextInputWithDrodownProps {
  id: number;
  name: string;
  displayName: string;
  variant: string;
  filterType: {
    id: number;
    name: string;
  };
  values: [];
  required: boolean;
  elements: [TextInputProps, ListSingleSelectProps];
  conditionalFields?: FormField[];
}

export interface ListSingleSelectProps {
  id: string;
  name: string;
  displayName: string;
  variant: string;
  filterType: {
    id: number;
    name: string;
  };
  values: {
    valueType: string;
    displayName: string;
    value: string;
  }[];
  displayHint: string;
  required: boolean;
  conditionalFields?: FormField[];
  currentValue: { values: string[] };
  fakeDisabled?: boolean;
}

export interface ListMultiSelectProps {
  id: string;
  name: string;
  displayName: string;
  variant: string;
  filterType: {
    id: string;
    name: 'ListMultiSelect';
  };
  values: {
    valueType: string;
    displayName: string;
    value: string;
  }[];
  required: boolean;
  showLabel: boolean;
  conditionalFields?: FormField[];
}

export interface TextInputProps {
  id: number;
  name: string;
  displayName: string;
  labelSubText?: string;
  variant: string;
  filterType: {
    id: number;
    name: string;
  };
  required: boolean;
  keyboardType?: string;
  inputType: string;
  currentValue?: string;
  validations: {
    comparison?: {
      type: string;
      amount: number;
      message: string;
    };
    customComparison?: (value: any) => string | undefined;
    regexps?: [
      {
        regex: string | RegExp;
        message: string;
      },
    ];
    minLength?: number;
  };
  options?: {
    shouldShowButton: boolean;
    displayHint: string;
    prefix?: string;
  };
  onBlur?: () => void;
  conditionalFields?: FormField[];
  // fakeDisabled is used when we want a form value to look and act disabled but we still want it to be send when a form is submitted
  fakeDisabled?: boolean;
}

export interface DropDownRangeProps {
  id: string;
  name: string;
  displayName: string;
  variant: string;
  filterType: {
    id: number;
    name: string;
  };
  values: {
    value: string;
    displayName: string;
  }[];
  required: boolean;
  conditionalFields?: FormField[];
}

export interface DropdownProps {
  value: string | string[];
  name: string;
  disabled?: boolean;
  dataTestId: string;
  id: string;
  multiple?: boolean;
  options: string | string[];
  onChange?: (currentValue: string | string[] | undefined) => void;
  conditionalFields?: FormField[];
}

export interface CheckboxProps {
  name: string;
  filterType: {
    name: ElementTypes.checkbox;
  };
  displayName: string;
  conditionalFields?: FormField[];
  required: boolean;
  disableOptionalText?: boolean;
}

export interface TextProps {
  name: string;
  filterType: {
    name: ElementTypes;
  };
  inputType: InputTypes;
  displayName: string;
  conditionalFields?: FormField[];
}
export interface SelectProps {
  name: string;
  filterType: {
    name: ElementTypes;
  };
  displayHint: string;
  value: string;
  inputType: InputTypes;
  options: any;
  conditionalFields?: FormField[];
}

export interface StepperProps {
  id: string;
  name: string;
  displayName: string;
  variant: string;
  filterType: {
    id: string;
    name: string;
  };
  currentValue: number;
  required: boolean;
  stepSize: number;
  min: number;
  max: number;
  onChange: (props: any) => void;
  isGroupedStepper?: boolean;
  conditionalFields?: FormField[];
  hasError?: boolean;
}

export interface GroupedStepperProps {
  id: string;
  name: string;
  variant: string;
  filterType: {
    id: string;
    name: string;
  };
  currentValue: any;
  required: boolean;
  elements: StepperProps[];
  onChange: (props: any) => void;
  conditionalFields?: FormField[];
  hasError?: boolean;
}

export interface TextAreaProps {
  id: number;
  name: string;
  value?: string;
  displayName: string;
  onChange: (currentValue: string) => void;
  onBlur?: () => void;
  displayHint: string;
  conditionalFields?: FormField[];
  minHeight?: string;
  showLabel?: boolean;
  validations?: {
    minLength?: number;
  };
  notification?: string | JSX.Element;
  hasError?: boolean;
}

export interface DatePickerProps {
  name: string;
  displayName: string;
  filterType: {
    id: number;
    name: string;
  };
  values: [];
  displayHint: string;
  required: boolean;
  options: {
    minDateEpochSecondsOffset: number;
    maxDateEpochSecondsOffset?: number;
    showYearPicker?: boolean;
  };
  showMonthYearPicker?: boolean;
  conditionalFields?: FormField[];
}

export interface RadioButtonGroupInputProps {
  name: string;
  displayName: string;
  filterType: {
    id: number;
    name: string;
  };
  values: {
    displayName: string;
    value: string;
  }[];
  displayHint: string;
  required: boolean;
  conditionalFields?: FormField[];
  showLabel: boolean;
}

export interface AutocompleteInputType {
  name: string;
  displayName: string;
  filterType: {
    id: number;
    name: ElementTypes.AutocompleteInputType;
  };
  values: [];
  displayHint: string;
  required: boolean;
  lockedDisplayName?: string;
  searchMessage?: string;
  placeholderText?: string;
  conditionalFields?: FormField[];
}

export interface FormSubHeadingProps {
  id: string;
  name: string;
  displayName: string;
  title: string;
  subtitle?: string;
  variant: string;
  filterType: {
    id: number;
    name: 'SubHeading';
  };
  values: [];
  required: boolean;
  conditionalFields?: FormField[];
  conditions?: FormConditions;
}

export interface FillerContentProps {
  id: number;
  name: string;
  filterType: {
    name: string;
  };
  fillerContent: JSX.Element;
  conditionalFields?: FormField[];
}

export interface Hidden {
  name: string;
  inputType: InputTypes;
  conditionalFields?: FormField[];
}

export type ShowWhenCondition = { fieldName: string; is: string | string[] };
export type DisableWhenCondition = { fieldName: string; is: any };
export type FormConditions = {
  showWhen?: ShowWhenCondition[];
  disableWhen?: DisableWhenCondition;
};

export type FormField = {
  disabled?: boolean;
  conditions?: FormConditions;
  currentValue?: any;
  labelSubText?: string;
  customComponent?: ReactNode | null;
} & (
  | TextInputWithDrodownProps
  | ListSingleSelectProps
  | ListMultiSelectProps
  | TextInputProps
  | DropDownRangeProps
  | DropdownProps
  | CheckboxProps
  | TextProps
  | SelectProps
  | StepperProps
  | GroupedStepperProps
  | TextAreaProps
  | DatePickerProps
  | RadioButtonGroupInputProps
  | AutocompleteInputType
  | FillerContentProps
  | Hidden
);

//TODO move it to types
export type PropertyCounts = {
  residentialForSale: number;
  residentialForRent: number;
  sharing: number;
  commercialForSale: number;
  commercialToRent: number;
  studentAccommodationForRent: number;
  studentAccommodationToShare: number;
  parkingForSale: number;
  parkingToRent: number;
  newHomes: number;
  holidayHomes: number;
};

//TODO move it to types
export type AreaTag = {
  displayName: string;
  displayValue: string;
  id: string;
  propertyCount: PropertyCounts;
};

export enum SECTIONS {
  BUY = 'residential-for-sale',
  RENT = 'residential-to-rent',
  SOLD = 'residential-sold',
  SHARE = 'sharing',
  COMMERCIAL_BUY = 'commercial-for-sale',
  COMMERCIAL_RENT = 'commercial-to-rent',
  STUDENT_ACCOMMODATION_RENT = 'student-accommodation-for-rent',
  STUDENT_ACCOMMODATION_SHARE = 'student-accommodation-to-share',
  PARKING_BUY = 'parking-for-sale',
  PARKING_RENT = 'parking-to-rent',
  NEW_HOMES = 'new-homes',
  HOLIDAY_HOMES = 'holiday-homes',
  INTERNATIONAL_BUY = 'international-for-sale',
  INTERNATIONAL_RENT = 'international-to-rent',
  VT_PROPERTY_PRICE_REGISTER = 'valuation-tool-property-price-register',
  VT_RESIDENTIAL_RENT = 'valuation-tool-residential-rent',
  VT_RESIDENTIAL_SALES = 'valuation-tool-residential-sales',
  VT_COMMERCIAL = 'valuation-tool-commercial',
}

export type LocationInputBoxOptions = {
  initWithFocus?: boolean;
  shouldShowOnDesktop?: boolean;
  selectedSection?: SECTIONS;
  shouldShowAreaCounts?: boolean;
  parentRef?: React.RefObject<HTMLElement>;
  currentPath: string;
};
