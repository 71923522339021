/*
Figma design: https://www.figma.com/file/cM5jrOpztBxcdQJN6YgXId/DAFT-PROPERTY-TOOLKIT-(NEW)?type=design&node-id=12218-43057&mode=design&t=CSsi2Kq76m3WOekW-0
*/

import {
  MaskedContainerProps,
  MaskedContainerShape,
  MaskedContainerSize,
} from './MaskedContainer.types';
import * as Styled from './MaskedContainer.styled';
import { ImageWrapper } from '../imageWrapper/ImageWrapper';

export const MaskedContainer = ({
  image,
  imageAlt,
  mobileSize,
  outline = false,
  size = MaskedContainerSize.S32,
  shape = MaskedContainerShape.CIRCLE,
  dataTracking,
}: MaskedContainerProps): JSX.Element => {
  return (
    <Styled.MaskedContainer
      mobileSize={mobileSize}
      size={size}
      shape={shape}
      outline={outline}
      {...(dataTracking && { 'data-tracking': dataTracking })}
    >
      <ImageWrapper alt={imageAlt} src={image} fill />
    </Styled.MaskedContainer>
  );
};

export default MaskedContainer;
