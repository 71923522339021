import React, { Component, ReactNode } from 'react';

import Downshift from 'downshift';

export type ListSelectValue = {
  valueType: string;
  displayName: string;
  value: string;
};

type DownshiftSelection = {
  displayName: string;
  value: string;
  valueType: string;
};

type OnChangeCallBack = {
  name?: string;
  currentValue: { values: string[] };
  searchQueryGroup?: string;
  filterType?: string;
};

export type ListMultiSelectProps = {
  name?: string;
  displayName?: string;
  alternativeStyle: boolean;
  showLabel: boolean;
  values: ListSelectValue[];
  currentValue?: { values: string[] };
  onChange?: ({
    name,
    currentValue,
    searchQueryGroup,
    filterType,
  }: OnChangeCallBack) => void;
  disabled?: boolean;
  searchQueryGroup?: string;
  children?: (stateAndHelpers: {
    getInputProps: any;
    getItemProps: any;
    getMenuProps: any;
    getLabelText: (
      selectedItems: ListSelectValue[],
      displayName: string,
    ) => void;
    getToggleButtonProps: any;
    isOpen: boolean;
    inputValue: string;
    selectedItems: ListSelectValue[];
  }) => ReactNode;
  onMenuChange?: (isOpen: boolean) => void;
  variant: string;
};

type ListMultiSelectState = {
  selectedItems: ListSelectValue[];
};

const CLEAR_ALL_ITEM_VALUE = '';

class ListMultiSelectFilter extends Component<
  ListMultiSelectProps,
  ListMultiSelectState
> {
  state = {
    selectedItems: this.getStateForSelectedItems(),
  };

  defaultValues: ListSelectValue[] = [
    {
      value: CLEAR_ALL_ITEM_VALUE,
      valueType: 'FilterStringValue',
      displayName: `${this.props.displayName}`,
    },
  ];

  getStateForSelectedItems() {
    let currentValues: string[] | string = this.props?.currentValue?.values || [
      CLEAR_ALL_ITEM_VALUE,
    ];

    if (!Array.isArray(currentValues)) {
      currentValues = [currentValues];
    }

    /*
     * We need to remove the reset value from the array if there are other
     * values
     */
    if (currentValues.length > 1) {
      currentValues = currentValues.filter(
        (item: string) => item !== CLEAR_ALL_ITEM_VALUE,
      );
    }
    return this.props.values.filter(
      (item: ListSelectValue) => currentValues.indexOf(item.value) > -1,
    );
  }

  componentDidUpdate(prevProps: ListMultiSelectProps) {
    if (
      JSON.stringify(prevProps.currentValue) !=
      JSON.stringify(this.props.currentValue)
    ) {
      this.setState({ selectedItems: this.getStateForSelectedItems() });
    }
  }

  stateReducer = (state: any, changes: any) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.changeInput:
      case Downshift.stateChangeTypes.blurButton:
      case Downshift.stateChangeTypes.blurInput:
        return {
          ...changes,
          isOpen: state.isOpen,
        };
      default:
        return changes;
    }
  };

  handleSelection = (downshiftSelectedItem: DownshiftSelection) => {
    const selectedItem: ListSelectValue = {
      valueType: 'FilterStringValue',
      displayName: downshiftSelectedItem.displayName,
      value: downshiftSelectedItem.value,
    };

    const callOnChange = () => {
      const { onChange, name, searchQueryGroup } = this.props;
      onChange &&
        onChange({
          searchQueryGroup,
          name,
          filterType: 'ListMultiSelect',
          currentValue: {
            values: this.state.selectedItems.map(
              (item: ListSelectValue) => item.value,
            ),
          },
        });
    };

    if (selectedItem.value === CLEAR_ALL_ITEM_VALUE) {
      this.clearAllValues(callOnChange);
    } else if (
      this.state.selectedItems.find(
        (item: ListSelectValue) => item.value === selectedItem.value,
      )
    ) {
      this.removeItem(selectedItem, callOnChange);
    } else {
      this.addSelectedItem(selectedItem, callOnChange);
    }
  };

  clearAllValues(callbackFunction: () => void) {
    /*
     * Here we want to reset selected values
     */
    this.setState(
      {
        selectedItems: this.defaultValues,
      },
      callbackFunction,
    );
  }

  removeItem(removeItem: ListSelectValue, callbackFunction: () => void) {
    /*
     * Here we want to filter out the selected item and reset all values if
     * there is only one selected value
     */
    this.setState(
      ({ selectedItems }) => ({
        selectedItems:
          selectedItems.length > 1
            ? [
                ...selectedItems.filter(
                  (selectedItem: ListSelectValue) =>
                    selectedItem.value !== removeItem.value,
                ),
              ]
            : this.defaultValues,
      }),
      callbackFunction,
    );
  }

  addSelectedItem(item: ListSelectValue, callbackFunction: () => void) {
    /*
     * Here we want to add the selected item and remove the all/clear value
     */
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [
          ...selectedItems.filter(
            (selectedItem: ListSelectValue) =>
              selectedItem.value !== CLEAR_ALL_ITEM_VALUE,
          ),
          item,
        ],
      }),
      callbackFunction,
    );
  }

  getStateAndHelpers(downshift: any) {
    const { selectedItems } = this.state;

    return {
      selectedItems,
      getLabelText: this.getLabelText,
      ...downshift,
    };
  }

  getLabelText = (selectedItems: ListSelectValue[], defaultLabel: string) => {
    if (
      !selectedItems ||
      selectedItems.length < 1 ||
      selectedItems[0].value === CLEAR_ALL_ITEM_VALUE
    ) {
      return defaultLabel;
    }
    if (selectedItems.length > 1) {
      return `${selectedItems[0].displayName} + ${selectedItems.length - 1}`;
    }
    return selectedItems[0].displayName;
  };

  render() {
    return (
      <Downshift
        id={`multi-list-${this.props.name}`}
        data-testid={`multi-list-${this.props.name}`}
        key={`multi-list-${this.props.name}`}
        stateReducer={this.stateReducer}
        //@ts-ignore FIX existing types error
        onChange={this.handleSelection}
        selectedItem={null}
        itemToString={(item) =>
          item ? item.displayName : CLEAR_ALL_ITEM_VALUE
        }
      >
        {(downshift: any) => (
          <div>
            {this.props?.children?.(this.getStateAndHelpers(downshift))}
          </div>
        )}
      </Downshift>
    );
  }
}

export { ListMultiSelectFilter };
