import React, { Component } from 'react';

import BlockyListMultiSelect from '../../molecules/ListMultiSelect/BlockyListMultiSelect';
import BlockyPopOutListMultiSelect from '../../molecules/ListMultiSelect/BlockyPopOutListMultiSelect';
import DefaultListMultiSelect from '../../molecules/ListMultiSelect/DefaultListMultiSelect';
import PopOutListMultiSelect from '../../molecules/ListMultiSelect/PopOutListMultiSelect';
import { ListMultiSelectProps } from '../../atoms/ListMultiSelect/ListMultiSelect';

type OnChangeCallBack = {
  name?: string;
  currentValue: { values: string[] };
  searchQueryGroup?: string;
  filterType?: string;
};

type ListMultiSelectState = { isOpen: boolean };

class VariableListMultiSelect extends Component<
  ListMultiSelectProps,
  ListMultiSelectState
> {
  state = { isOpen: false };

  shouldComponentUpdate(
    nextProps: ListMultiSelectProps,
    nextState: ListMultiSelectState,
  ) {
    return (
      this.state.isOpen !== nextState.isOpen ||
      JSON.stringify(this.props.currentValue) !==
        JSON.stringify(nextProps.currentValue)
    );
  }

  onChange = (searchFilterObject: OnChangeCallBack) => {
    if (this.props.onChange) {
      this.props.onChange(searchFilterObject);
    }
  };

  onMenuChange = (isOpen: boolean) => {
    this.setState({ isOpen });
  };

  renderComponent = (props: ListMultiSelectProps) => {
    const { variant, alternativeStyle, displayName } = props;

    if (variant === 'DAFT_BLOCKY') {
      return alternativeStyle ? (
        <BlockyPopOutListMultiSelect
          {...props}
          onChange={this.onChange}
          onMenuChange={this.onMenuChange}
        />
      ) : (
        <BlockyListMultiSelect
          {...props}
          onChange={this.onChange}
          aria-label={displayName}
        />
      );
    }

    return alternativeStyle ? (
      <PopOutListMultiSelect
        {...props}
        onChange={this.onChange}
        aria-label={displayName}
      />
    ) : (
      <DefaultListMultiSelect
        {...props}
        onChange={this.onChange}
        aria-label={displayName}
      />
    );
  };

  render() {
    return (
      <div
        data-testid={`multi-select-filter-${this.props.name}`}
        className={this.state.isOpen ? 'hasOpenMenu' : ''}
      >
        {this.renderComponent(this.props)}
      </div>
    );
  }
}

export default VariableListMultiSelect;
