import styled from 'styled-components';

export const StyledIframeWrapper = styled.div<{
  thumbnail?: string;
  overlay?: boolean;
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  ${({ thumbnail, overlay }) =>
    thumbnail &&
    `background: ${
      overlay
        ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), '
        : ''
    }url(${thumbnail}) no-repeat center center`};
  background-size: cover;
`;

export const StyledOverlay = styled.div<{ icon?: string }>`
  position: absolute;
  ${({ icon }) => icon && `background: url(${icon}) no-repeat center center;`}
  background-size: 30%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
