export enum NotificationStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFORMATIONAL = 'INFORMATIONAL',
  GENERAL = 'GENERAL',
}

export enum NotificationFontSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
