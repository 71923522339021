import * as Types from './Notification.types';
import * as Styled from './Notification.styled';
import { Icon } from '../../atoms';

export const Notification = ({
  notificationSizes = Types.NotificationFontSizes.MEDIUM,
  notificationStatus = Types.NotificationStatus.SUCCESS,
  notificationVariants = Types.NotificationVariants.Toast,
  closeIcon = true,
  text,
  title,
  icon,
  cta,
  onChange,
  titleColor,
  role,
}: Types.NotificationProps) => {
  return (
    <Styled.Wrapper
      {...(role && { role: role })}
      notificationSizes={notificationSizes}
      notificationStatus={notificationStatus}
      notificationVariants={notificationVariants}
      data-testid="notification-wrapper"
    >
      <Styled.InnerWrapper>
        <Styled.StyledIcon
          notificationSizes={notificationSizes}
          notificationStatus={notificationStatus}
          notificationVariants={notificationVariants}
        >
          {icon}
        </Styled.StyledIcon>
        <Styled.TextIconCTAWrapper>
          <Styled.TextButtonWrapper notificationSizes={notificationSizes}>
            <Styled.TextIconWrapper>
              <Styled.TextWrapper notificationSizes={notificationSizes}>
                {title && (
                  <Styled.Title
                    notificationSizes={notificationSizes}
                    titleColor={titleColor}
                  >
                    {title}
                  </Styled.Title>
                )}
                <Styled.Paragrapgh notificationSizes={notificationSizes}>
                  {text}
                </Styled.Paragrapgh>
              </Styled.TextWrapper>
            </Styled.TextIconWrapper>
            {closeIcon && (
              <Styled.ClosedIcon
                onClick={onChange}
                data-testid="close-button"
                notificationSizes={notificationSizes}
              >
                <Icon icon="CLOSE" size={16}></Icon>
              </Styled.ClosedIcon>
            )}
          </Styled.TextButtonWrapper>
          {cta && (
            <Styled.CtaWrapper
              notificationSizes={notificationSizes}
              data-testid="cta-wrapper"
            >
              {cta}
            </Styled.CtaWrapper>
          )}
        </Styled.TextIconCTAWrapper>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};
