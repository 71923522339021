export enum Spacing {
  HUG = 'HUG',
  SPACED = 'SPACED',
}

type AlignType = 'start' | 'center' | 'end';

type SpacingGap = '8px' | '12px' | '16px';

export type TInfoItem = {
  children: JSX.Element | JSX.Element[];
  spacing?: Spacing;
  align? : AlignType;
  columnGap?: SpacingGap;
};

export type TInfoItemStyle = {
  spacing?: Spacing;
  align?: AlignType;
  columnGap?: SpacingGap;
};
