import { useState } from 'react';

export const useHeader = () => {
  const [showNav, setShowNav] = useState(false);
  const toggleNavigation = () => {
    document.body.style.overflow = showNav ? 'auto' : 'hidden';
    setShowNav(!showNav);
  };
  return { showNav, toggleNavigation };
};
