import React from 'react';
import * as Styled from './Badge.styled';
import * as Types from './Badge.types';
import { TextWrapper } from './Badge.styled';

export const Badge = ({
  children,
  badgeStyle = Types.BadgeStyle.OUTLINED,
  badgeColor = Types.BadgeColor.BLUE,
  badgeSize = Types.BadgeSize.MEDIUM,
  icon,
  iconAlignment = 'left',
  testId,
}: Types.BadgeProps) => {
  const iconSize = badgeSize === 'SMALL' ? 12 : 16;

  return (
    <Styled.BadgeWrapper>
      <Styled.BadgeContainer
        badgeStyle={badgeStyle}
        badgeColor={badgeColor}
        badgeSize={badgeSize}
        data-badgecolor={badgeColor}
        data-badgesize={badgeSize}
        data-testid={testId || `badge-${badgeStyle}-icon-${iconAlignment}`}
        iconAlignment={iconAlignment}
      >
        {icon && React.cloneElement(icon, { size: iconSize })}
        <TextWrapper>{children}</TextWrapper>
      </Styled.BadgeContainer>
    </Styled.BadgeWrapper>
  );
};
