import { FilterRequestAPI } from '../Filters/FilterTypes';
import { hasValidValue } from '../Search/SearchUtils';

export const checkZeroResults = (filterAPI: FilterRequestAPI[]) => {
  const buttons = filterAPI.map((filter: FilterRequestAPI) => {
    const currentValue = filter.currentValue;
    let filterObj: any;
    if (typeof currentValue === 'string') {
      filterObj = {
        currentValue,
        name: filter.name,
        searchQueryGroup: filter.searchQueryGroup,
      };
    } else {
      filterObj = {
        searchQueryGroup: filter.searchQueryGroup,
        ...currentValue,
      };
    }
    return hasValidValue(filterObj) ? filter : null;
  });
  return buttons;
};
