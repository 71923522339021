export class UrlSeoService {
  public paramObjects: any[];
  public get pathStrings() {
    return this.paramObjects.map((paramObject) => paramObject.pathString);
  }

  constructor(
    queryParams: (string | { queryParam: string; defaultString: string })[],
  ) {
    const normalizedParams = queryParams.map(
      (
        unnormalizedQueryParam:
          | string
          | { queryParam: string; defaultString: string },
      ) => {
        if (typeof unnormalizedQueryParam === 'string') {
          return { queryParam: unnormalizedQueryParam, defaultString: null };
        }
        return unnormalizedQueryParam;
      },
    );

    this.paramObjects = normalizedParams.map(
      ({ queryParam, defaultString }) => {
        const pathString = `${queryParam}Path`;
        return {
          queryParam,
          pathString,
          defaultString,
          currentValue: [],
        };
      },
    );
  }

  public getParamObject(name: string) {
    return this.paramObjects.find(
      (paramObject: any) => paramObject.queryParam === name,
    );
  }

  public reconcileSEOMultiParamsFromURL(queryObject: any) {
    const query = { ...queryObject };

    this.paramObjects.forEach((paramObject: any) => {
      const pathNameValue = query[paramObject.pathString];
      const queryValues = query[paramObject.queryParam] || [];
      if (pathNameValue) {
        query[paramObject.queryParam] = queryValues.length
          ? [pathNameValue, ...queryValues]
          : pathNameValue;
        delete query[paramObject.pathString];
      }
    });

    return query;
  }

  public updateCurrentValue(queryParam: string, currentValue: any[]) {
    const paramObjectIndex = this.paramObjects.findIndex(
      (paramObject) => paramObject.queryParam === queryParam,
    );

    if (paramObjectIndex !== -1) {
      this.paramObjects[paramObjectIndex].currentValue = currentValue;
    }
  }

  public resetAllParams() {
    this.paramObjects.map((paramObject) => {
      paramObject.currentValue = [];
    });
  }

  public makeReconciledQueryObject(queryObject: any) {
    let query = { ...queryObject };
    let isMultiParam = false;

    // if any parameters (other than the last one) have more than one value, set isMultiParam to true
    // this is used to switch everything to query params
    this.paramObjects.forEach(({ currentValue }, index) => {
      if (index === this.paramObjects.length - 1) {
        return;
      }
      if (
        typeof currentValue !== 'string' &&
        currentValue &&
        currentValue.length > 1
      ) {
        isMultiParam = true;
      }
    });

    this.paramObjects.forEach(
      ({ currentValue = [], pathString, queryParam, defaultString }) => {
        const isValidCurrentValue = currentValue.length && currentValue[0];

        // if no valid value, either add the default string, or remove the properties that we aren't using
        if (!isValidCurrentValue) {
          if (defaultString) {
            query = {
              ...query,
              [pathString]: defaultString,
            };
            delete query[queryParam];
          } else {
            delete query[pathString];
            delete query[queryParam];
          }
          return query;
        }

        // determine whether we need to use a path query for a single value
        // or a query string for multiple values
        const useQueryParams =
          typeof currentValue !== 'string' &&
          (currentValue.length > 1 || isMultiParam);

        const name = useQueryParams ? queryParam : pathString;
        if (useQueryParams) {
          delete query[pathString];
          if (defaultString) {
            query = {
              ...query,
              [pathString]: defaultString,
            };
          }
        } else {
          delete query[queryParam];
        }

        query = {
          ...query,
          [name]: currentValue.length === 1 ? currentValue[0] : currentValue,
        };
      },
    );

    if (
      queryObject.geoSearchType === 'BBOX' ||
      queryObject.geoSearchType === 'CUSTOM_SHAPES' ||
      queryObject.geoSearchType === 'BBOX_CUSTOM_SHAPES'
    ) {
      query.locationPath = 'mapArea';
      delete query.radius;
    }

    return query;
  }
}
