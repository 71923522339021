import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const List = styled.ul`
  position: fixed;
  z-index: 9999999;
  top: ${({ theme }) => theme.toolkit.spacing.M24};
  max-width: 340px;
  left: 50%;
  right: 50%;
  width: 100%;
  transform: translate(-50%, 0);

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.small}) {
    top: ${({ theme }) => theme.toolkit.spacing.M32};
    right: ${({ theme }) => theme.toolkit.spacing.M32};
    left: auto;
    transform: translate(0, 0);
  }
`;

export const ToastWrapper = styled.li<{ removing?: boolean }>`
  margin-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
  animation: ${(props) => (props.removing ? slideOut : slideIn)} 0.3s ease-out;
  padding: 0 ${({ theme }) => theme.toolkit.spacing.M16};

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.small}) {
    padding: 0;
  }
`;
