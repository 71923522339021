import { baseHeaders, handleAPIError } from '@dist-property-frontend/configs';

import axios from 'axios';

/**
 * API Gateway will receive dynamic base URL, determined by the app that is using it
 */
const apiGateway = axios.create({
  headers: { ...baseHeaders },
});

/**
 * @returns suggests locations (county/city/area...) for a given text
 */
const suggestions = async ({
  text,
  path = '',
  baseURL,
}: {
  text: string;
  path: string;
  baseURL: string;
}): Promise<any> => {
  try {
    const response = await apiGateway({
      method: 'post',
      url: `/old/v1/autocomplete${path}`,
      baseURL,
      data: {
        text,
      },
    });
    const { data } = response;
    return [...data];
  } catch (error) {
    return handleAPIError(error);
  }
};

export const AutocompleteAPI = {
  suggestions,
};
