import styled, { css } from 'styled-components';
import { StyleProps } from './Text.types';

export const Wrapper = styled.span<StyleProps>`
  color: ${({ textColor }) => (textColor ? textColor : 'inherit')};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight
      ? theme.toolkit.typography.fontWeights[
          fontWeight as keyof typeof theme.toolkit.typography.fontWeights
        ]
      : 'inherit'};
  ${({ size, theme }) =>
    size &&
    theme.toolkit.typography.fontSizes[
      size as keyof typeof theme.toolkit.typography.fontSizes
    ]}
  ${({ lineClamp }) =>
    lineClamp &&
    css`
      /* Fallback styles for browsers that do not support -webkit-line-clamp */
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @supports (-webkit-line-clamp: ${lineClamp}) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${lineClamp};
        white-space: normal;
      }
    `}
`;
