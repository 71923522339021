import * as Types from './StickyButton.types';
import * as Styled from './StickyButton.styled';

export const StickyButton = ({
  children,
  fillContainer,
}: Types.TStickyButton) => {
  return (
    <Styled.Overlay>
      <Styled.ButtonContainer fillContainer={fillContainer}>
        {children}
      </Styled.ButtonContainer>
    </Styled.Overlay>
  );
};
