import styled, { css } from 'styled-components';
import { TTooltipPositions, TTooltipSizes } from './Tooltip.types';

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
`;

export const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextWrapper = styled.div<{
  closeIcon?: boolean;
  image: JSX.Element | undefined;
  size: TTooltipSizes;
}>`
  display: flex;
  ${({ image, size }) =>
    image &&
    (size === TTooltipSizes.MEDIUM
      ? 'margin-left: 16px;'
      : 'margin-left: 12px;')};
  flex-direction: column;
  align-items: flex-start;
  ${({ theme, closeIcon }) =>
    closeIcon && `margin-right: ${theme.toolkit.spacing.S8}`};
`;

export const HeaderWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S4};
`;

export const ClosedIcon = styled.button`
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARK};
  display: flex;
  margin: 0 0 0 auto;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Image = styled.div`
  height: 100%;
`;

// Define reusable position classes
// For mobile view < 440px the tooltip will be position at the center of the screen
const tooltipPositionStyles = ({
  spanLeft,
  spanHeight,
  spanWidth,
  tooltipPosition,
  width,
}: {
  spanHeight: number;
  spanLeft: number;
  spanWidth: number;
  tooltipPosition: TTooltipPositions;
  width: number;
  toolTipWidth?: number;
}) => {
  switch (tooltipPosition) {
    case TTooltipPositions.BOTTOM_START:
      return css`
        top: calc(
          ${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap)
        );
        transform: translateX(-${spanWidth / 2}px);

        &::before {
          bottom: 100%;
          left: calc(${spanWidth / 2}px - var(--tooltip-arrow-size));
          border-bottom-color: ${({ theme }) =>
            theme.toolkit.brand.primary.TEXTCONTRAST};
        }
      `;
    case TTooltipPositions.BOTTOM_CENTER:
      return css`
        top: calc(
          ${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap)
        );
        transform: translateX(-50%);

        &::before {
          bottom: 100%;
          left: calc(var(--tooltip-width-web) / 2 - var(--tooltip-arrow-size));
          border-bottom-color: ${({ theme }) =>
            theme.toolkit.brand.primary.TEXTCONTRAST};
          @media only screen and (${({ theme }) =>
              theme.toolkit.mediaQuery.xsmallOnly}) {
            left: calc(
              var(--tooltip-width-mobile) / 2 - var(--tooltip-arrow-size)
            );
          }
        }
      `;
    case TTooltipPositions.BOTTOM_END:
      return css`
        top: calc(
          ${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap)
        );
        transform: translateX(
          calc(var(--tooltip-width-web) * -1 + ${spanWidth / 2}px)
        );
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.xsmallOnly}) {
          transform: translateX(
            calc(var(--tooltip-width-mobile) * -1 + ${spanWidth / 2}px)
          );
        }
        &::before {
          bottom: 100%;
          right: calc(${spanWidth / 2}px - var(--tooltip-arrow-size));
          border-bottom-color: ${({ theme }) =>
            theme.toolkit.brand.primary.TEXTCONTRAST};
        }
      `;
    case TTooltipPositions.BOTTOM:
      return css`
        top: calc(
          ${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap)
        );
        transform: translateX(
          calc(-${spanLeft}px + (${width}px - var(--tooltip-width-mobile)) / 2)
        );
        &::before {
          bottom: 100%;
          left: calc(
            ${spanLeft}px - (${width}px - var(--tooltip-width-mobile)) / 2
          );
          border-bottom-color: ${({ theme }) =>
            theme.toolkit.brand.primary.TEXTCONTRAST};
        }
      `;
  }
};

/* Absolute positioning */
export const Tip = styled.div<{
  size: TTooltipSizes;
  spanHeight: number;
  spanLeft: number;
  spanWidth: number;
  tooltipPosition: TTooltipPositions;
  toolTipWidth: number;
  width: number;
}>`
  --tooltip-arrow-size: ${({ theme }) => theme.toolkit.spacing.S8};
  --tooltip-width-web: ${({ toolTipWidth }) => `${toolTipWidth}px`};
  // add mobile width
  --tooltip-width-mobile: 288px;
  --tooltip-gap: ${({ theme }) => theme.toolkit.spacing.S4};

  background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.2);
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
  left: ${({ spanWidth }) => `${spanWidth / 2}px`};
  padding: ${({ theme, size }) =>
    size === TTooltipSizes.MEDIUM
      ? theme.toolkit.spacing.M16
      : theme.toolkit.spacing.S12};
  position: absolute;
  width: var(--tooltip-width-web);

  z-index: 10;
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.xsmallOnly}) {
    width: var(--tooltip-width-mobile);
  }

  /* CSS border triangles */
  &::before {
    content: ' ';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
  }

  ${({ tooltipPosition, spanHeight, spanLeft, spanWidth, width }) =>
    tooltipPositionStyles({
      tooltipPosition,
      spanHeight,
      spanLeft,
      spanWidth,
      width,
    })}
`;

export const TooltipBadge = styled.div<{
  spanHeight: number;
  spanLeft: number;
  spanWidth: number;
  tooltipPosition: TTooltipPositions;
  toolTipWidth: number;
  width: number;
}>`
  display: none;

  // >= 1024px
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    --tooltip-arrow-size: 0px;
    --tooltip-width-web: ${({ toolTipWidth }) => `${toolTipWidth}px`};
    --tooltip-width-mobile: ${({ toolTipWidth }) => `${toolTipWidth}px`};
    --tooltip-gap: ${({ theme }) => theme.toolkit.spacing.S4};
    display: block;
    position: absolute;
    text-transform: uppercase;
    left: ${({ spanWidth }) => `${spanWidth / 2}px`};
    z-index: 10;

    ${({ tooltipPosition, spanHeight, spanLeft, spanWidth, width }) =>
      tooltipPositionStyles({
        tooltipPosition,
        spanHeight,
        spanLeft,
        spanWidth,
        width,
      })}
  }
`;
