import type { ButtonHTMLAttributes, ReactNode, ReactElement } from 'react';

export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  GHOST = 'GHOST',
}

export enum ButtonSizes {
  X_SMALL = 'X_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type ButtonContainerStyleProps = {
  fullWidth?: boolean;
  hasChildren?: ReactNode;
  isButtonGroup?: boolean;
  isLinkButtonGroup?: boolean;
  isMobile?: boolean;
  position?: 'left' | 'right';
};

export type ButtonStyleProps = {
  active?: boolean;
  buttonSize?: ButtonSizes;
  buttonType: string;
  fullWidth?: boolean;
  hasChildren?: ReactNode;
  Icon?: string;
  isButtonGroup?: boolean;
  isIconOnly?: boolean;
  isLinkButtonGroup?: boolean;
  isMobile?: boolean;
  loader?: boolean;
  position?: 'left' | 'right';
  useLegacyMinWidth?: boolean;
  noPadding?: boolean;
};

export type ButtonProps = {
  children?: ReactNode;
  buttonType?: ButtonTypes;
  buttonSize?: ButtonSizes;
  'data-testid'?: string;
  'data-tracking'?: string;
  fullWidth?: boolean;
  id?: string;
  href?: string;
  classNames?: string;
  disabled?: boolean;
  active?: boolean;
  element?: React.ElementType;
  testId?: string;
  isButtonGroup?: boolean;
  isLinkButtonGroup?: boolean;
  position?: 'left' | 'right';
  Icon?: ReactElement;
  loader?: boolean;
  fillIcon?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  // This is a temporary prop to allow the use of the legacy 120px minWidth
  // We want to remove it eventually but it causes issues in some of the places when removed
  // Once we have solved the issue we can remove this prop. It should be false in all new places
  // Ticket to track the minWidth issues: https://distilledsch.tpondemand.com/entity/97281-remove-responsiveness-from-button
  useLegacyMinWidth?: boolean;
  noPadding?: boolean;
  openInNewTab?: boolean;
  trackingId?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;
