import styled from 'styled-components';

export const NavItem = styled.li`
  margin-bottom: ${({ theme }) => theme.toolkit.spacing.M16};

  button {
    padding: 0;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    height: ${({ theme }) => theme.toolkit.spacing.M24};
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};
    justify-content: center;
    text-decoration: none;

    a {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};

      &:hover {
        color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-decoration: ${({ theme }) => theme.toolkit.spacing.S2} solid
          underline ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-underline-offset: ${({ theme }) => theme.toolkit.spacing.S8};
      }
    }

    &.active {
      a {
        color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-decoration: ${({ theme }) => theme.toolkit.spacing.S2} solid
          underline ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-underline-offset: ${({ theme }) => theme.toolkit.spacing.S8};
      }
    }
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.xlarge} {
    padding: ${({ theme }) => theme.toolkit.spacing.S8} 0;

    a {
      border-bottom: ${({ theme }) => theme.toolkit.spacing.S2};
      ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
    }
  }
`;

export const SubNavWrapper = styled.ul<{ bgColor?: string }>`
  padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
  @media ${({ theme }) => theme.toolkit.mediaQuery.mediumOnly} {
    margin-top: ${({ theme }) => theme.toolkit.spacing.M16};
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    display: none;
    position: absolute;
    min-width: 12.5rem;
    background: ${({ theme }) => theme.toolkit.brand.background.primary};
    border: 0.0625rem solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    padding: ${({ theme }) => theme.toolkit.spacing.S12};
  }
  &[aria-hidden='false'] {
    display: block;
  }
`;

export const SubNavItem = styled.li`
  display: block;
  margin-bottom: ${({ theme }) => theme.toolkit.spacing.M24};
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.S12};
  }
  a {
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};

    &:hover {
      color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
      text-decoration: ${({ theme }) => theme.toolkit.spacing.S2} solid
        underline ${({ theme }) => theme.toolkit.brand.primary.BASE};
      text-underline-offset: ${({ theme }) => theme.toolkit.spacing.S8};
    }

    &.active {
      color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
      text-decoration: ${({ theme }) => theme.toolkit.spacing.S2} solid
        underline ${({ theme }) => theme.toolkit.brand.primary.BASE};
      text-underline-offset: ${({ theme }) => theme.toolkit.spacing.S8};
    }
  }
`;
