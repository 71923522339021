import React from 'react';
import { KeywordSearchProps } from './KeywordSearch.types';
import { Input } from '../../atoms';
import FormControl from '../formControl/FormControl';
import { KeywordSearchForm } from './KeywordSearch.styled';

export const KeywordSearch = (props: KeywordSearchProps) => {
  const {
    disabled = false,
    leftSlot,
    maxLength,
    minLength,
    onChange,
    onClear,
    onSubmit,
    placeholder = 'Search...',
    rightSlots = [],
    value = '',
    errorText = '',
    setErrorText = () => null,
    dataTracking,
    ...rest
  } = props;

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedValue = value.trim();

    if (
      minLength &&
      trimmedValue.length < minLength &&
      trimmedValue.length !== 0
    ) {
      setErrorText(`Please enter at least ${minLength} characters`);
      return;
    }

    return onSubmit(trimmedValue);
  };

  const handleOnChange = (value: string) => {
    if (errorText) {
      setErrorText('');
    }
    return onChange(value);
  };

  return (
    <KeywordSearchForm {...rest} onSubmit={handleOnSubmit}>
      <FormControl
        disabled={disabled}
        notificationMessage={errorText}
        showNotification={Boolean(errorText)}
        dataTracking={dataTracking}
      >
        <Input
          enterKeyHint="search"
          leading={leftSlot}
          maxLength={maxLength}
          onChange={(event) => handleOnChange(event.target.value)}
          onClear={onClear}
          placeholder={placeholder}
          role="search"
          trailing={rightSlots}
          type="text"
          value={value}
          data-tracking={dataTracking}
        />
      </FormControl>
    </KeywordSearchForm>
  );
};

export default KeywordSearch;
