import type { TSpace, TSpaceValue } from './Space.types';
import * as Styled from './Space.styled';
import { TSpacingValue } from '@dist-property-frontend/configs';

const getSpaceBreakpoints = (
  xs?: TSpacingValue,
  sm?: TSpacingValue,
  md?: TSpacingValue,
  lg?: TSpacingValue,
  xl: TSpacingValue = 'S2',
) => ({
  xs: xs || sm || md || lg || xl,
  sm: sm || md || lg || xl,
  md: md || lg || xl,
  lg: lg || xl,
  xl: xl,
});

export const Space = (props: TSpace) => {
  const { space, ...rest } = props;

  const spaceProp: TSpaceValue =
    typeof space === 'string'
      ? space
      : {
          ...getSpaceBreakpoints(
            space?.xs,
            space?.sm,
            space?.md,
            space?.lg,
            space?.xl,
          ),
        };

  return <Styled.Space space={spaceProp} {...rest} />;
};

export default Space;
