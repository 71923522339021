import React from 'react';

import styled from 'styled-components';

import { SingleSelectFilter } from '../../atoms/SingleSelect/SingleSelectFilter';
import {
  BlockySelect,
  BlockySelectCSS,
} from '../../../Styles/SharedFilterStyles';

const StyledSingleSelectFilter = styled(SingleSelectFilter)<any>`
  ${BlockySelectCSS};
`;

const BlockySingleSelectFilter = (props: any) => (
  <div>
    <BlockySelect>
      <StyledSingleSelectFilter {...props} />
    </BlockySelect>
  </div>
);

export default BlockySingleSelectFilter;
