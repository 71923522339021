export type TBoundary = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
};

export enum TTooltipSizes {
  BADGE = 'badge',
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum TTooltipPositions {
  BOTTOM = 'bottom',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
}

export type TTooltip = {
  children: JSX.Element;
  closeIcon: boolean;
  content?: JSX.Element | string;
  delay?: number;
  header?: string;
  image?: JSX.Element;
  size?: TTooltipSizes;
  toolTipWidth?: number;
  hideTooltip?: boolean;
};

export type TTooltipHook = {
  children: JSX.Element;
  size?: TTooltipSizes;
  delay?: number;
};
