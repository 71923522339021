import { NewTabIcon } from '@dsch/icons';
import Link from 'next/link';
import React, { ComponentProps, ReactNode, forwardRef } from 'react';

import { IconWithContentAndLabel } from '../../../../../components/Toolkit/IconWithContentAndLabel/IconWithContentAndLabel';
import { NavigationItemProps } from '../../../../../config/headerNavigation';

import CollapsingItem from './CollapsingItem';
import * as Styled from './Navigation.styled';
import { LabelContainerProps, NavigationProps } from './Navigation.types';
import {
  Badge,
  BadgeColor,
  BadgeSize,
  BadgeStyle,
} from '@dist-property-frontend/toolkit';
import { useCheckIsMobile } from '@dist-property-frontend/services';

const TopLevelItemActiveFakeButtonWithRef = forwardRef((props: any, ref) => (
  <Styled.TopLevelItemActiveFakeButton {...props} {...ref} />
));

const TopLevelItemActiveLinkWithRef = forwardRef(
  (props: ComponentProps<typeof Styled.TopLevelItemActiveLink>, ref) => (
    <Styled.TopLevelItemActiveLink {...props} {...ref} />
  ),
);

const NavTitleWrapper = ({
  title,
  hasNewLabel,
  hasNewSubtitleLabel,
  labelSize = 'xsmall',
  labelText = 'New',
  labelType = 'success',
}: LabelContainerProps): JSX.Element => {
  return (
    <>
      {title}
      {hasNewLabel && (
        <Styled.LabelNewContainer>
          <Badge
            badgeColor={BadgeColor.GREEN}
            badgeSize={BadgeSize.SMALL}
            badgeStyle={BadgeStyle.FILLED_LIGHT}
          >
            NEW
          </Badge>
        </Styled.LabelNewContainer>
      )}
      {hasNewSubtitleLabel && (
        <Styled.LabelNewSubItem
          labelSize={labelSize}
          labelText={labelText}
          labelType={labelType}
          testId={`new-label-nav-${title}`}
        />
      )}
    </>
  );
};

type NavLinkProps = {
  href: string;
  /**
   * This must be an anchor tag. We'll wrap it based on configuration from headerNavigation.ts
   */
  children: ReactNode;
  /**
   * @see apps/daft/config/headerNavigation.ts => NavigationItem['renderAs']
   */
  renderAs: 'next/link' | 'anchor';
};
const NavLink = ({ href, children, renderAs = 'next/link' }: NavLinkProps) => {
  // href can be undefined here - and is for some top-level links like "Mortgages".
  return href && renderAs === 'next/link' ? (
    <Link href={href} passHref legacyBehavior>
      {children}
    </Link>
  ) : (
    // Sometimes we want to use an anchor tag instead of next/link so we do a full document
    // navigation instead of a client-side navigation. See the 'renderAs' property in
    // apps/daft/config/headerNavigation.ts
    <>{children}</>
  );
};

type NavSectionProps = NavigationItemProps & {
  asPath: string;
  rightAlign: boolean;
};

const NavSection = ({
  title,
  href,
  onlyShowOnDesktop,
  subItems,
  basePath,
  dontShowActiveForSubPaths,
  opensInNewTab,
  hasNewLabel = false,
  rightAlign,
  asPath,
}: NavSectionProps) => {
  const handleFakeButtonKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
  ): void | boolean => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const { isMobileState } = useCheckIsMobile();

  return (
    <Styled.StyledLI
      data-testid={`nav-item-${title.split(' ').join('').toLowerCase()}`}
      display={onlyShowOnDesktop ? 'none' : 'block'}
    >
      {subItems ? (
        <CollapsingItem
          openOnHover={true}
          dataTestId="nav-menu"
          labelIsLink={Boolean(href)}
          label={
            href ? (
              <NavLink
                href={href}
                // Root level links are always plain anchor tags so we don't pre-load the entire
                // application when you navigate to a page.
                renderAs={'anchor'}
              >
                <TopLevelItemActiveFakeButtonWithRef
                  activeClassName="active"
                  dontShowActiveForSubPaths={dontShowActiveForSubPaths}
                  aria-haspopup="true"
                  data-testid="top-level-nav-item"
                  asPath={asPath}
                  basePath={basePath}
                  href={href}
                  rightAlign={rightAlign}
                  onKeyDown={handleFakeButtonKeyDown}
                  target={opensInNewTab ? '_blank' : '_self'}
                >
                  {hasNewLabel ? (
                    <NavTitleWrapper title={title} hasNewLabel={hasNewLabel} />
                  ) : (
                    title
                  )}
                </TopLevelItemActiveFakeButtonWithRef>
              </NavLink>
            ) : (
              <Styled.NavTitleMobileWrapper>
                <Styled.TopLevelItemActiveButton
                  dontShowActiveForSubPaths={dontShowActiveForSubPaths}
                  activeClassName="active"
                  data-testid="top-level-nav-item"
                  aria-haspopup="true"
                  asPath={asPath}
                  basePath={basePath}
                  rightAlign={rightAlign}
                >
                  {hasNewLabel && !isMobileState ? (
                    <NavTitleWrapper title={title} hasNewLabel={hasNewLabel} />
                  ) : (
                    title
                  )}
                </Styled.TopLevelItemActiveButton>
                {hasNewLabel && isMobileState && (
                  <Styled.LabelNewContainer>
                    <Badge
                      badgeColor={BadgeColor.GREEN}
                      badgeSize={BadgeSize.SMALL}
                      badgeStyle={BadgeStyle.FILLED_LIGHT}
                    >
                      NEW
                    </Badge>
                  </Styled.LabelNewContainer>
                )}
              </Styled.NavTitleMobileWrapper>
            )
          }
        >
          <Styled.NavSubLinkList
            rightAlign={rightAlign}
            data-testid="nav-sub-link-list"
          >
            {subItems.map((item, index) => (
              <Styled.NavSubLinkListItem
                key={`sublink-${index}`}
                hideLinkOnDesktop={item.hideLinkOnDesktop}
                hideLinkOnMobile={item.hideLinkOnMobile}
                data-testid="nav-sub-link-list-item"
              >
                <NavLink href={item.href} renderAs={item.renderAs}>
                  <Styled.StyledActiveLink
                    activeClassName="active"
                    dontShowActiveForSubPaths={item.dontShowActiveForSubPaths}
                    data-testid="active-nav-link"
                    asPath={asPath}
                    basePath={item.basePath}
                    href={item.href}
                    target={item.opensInNewTab ? '_blank' : '_self'}
                    data-tracking={`${
                      item.dataTracking
                        ? item.dataTracking
                        : `nav-${title.split(' ').join('').toLocaleLowerCase()}`
                    }`}
                  >
                    {item.opensInNewTab && (
                      <IconWithContentAndLabel
                        dataTestID="IconWithContentAndLabel"
                        IconComponent={<NewTabIcon />}
                        content={<>{item.title}</>}
                        iconHAlignment={'right'}
                        iconVAlignment={'center'}
                        spaceBetween="small"
                        justify="space-between"
                      />
                    )}

                    {item.hasNewSubtitleLabel ? (
                      <NavTitleWrapper
                        title={item.title}
                        hasNewSubtitleLabel={item.hasNewSubtitleLabel}
                        hasNewLabel={false}
                      />
                    ) : (
                      !item.opensInNewTab && item.title
                    )}
                  </Styled.StyledActiveLink>
                </NavLink>
              </Styled.NavSubLinkListItem>
            ))}
          </Styled.NavSubLinkList>
        </CollapsingItem>
      ) : (
        <NavLink
          href={href}
          // Root level links are always plain anchor tags so we don't pre-load the entire
          // application when you navigate to a page.
          renderAs={'anchor'}
        >
          <TopLevelItemActiveLinkWithRef
            activeClassName="active"
            data-testid={'top-level-active-nav-link'}
            dontShowActiveForSubPaths={dontShowActiveForSubPaths}
            asPath={asPath}
            basePath={basePath}
            rightAlign={rightAlign}
            href={href}
          >
            {hasNewLabel ? (
              <NavTitleWrapper title={title} hasNewLabel={hasNewLabel} />
            ) : (
              title
            )}
          </TopLevelItemActiveLinkWithRef>
        </NavLink>
      )}
    </Styled.StyledLI>
  );
};

export const Navigation = ({
  navigationItems,
  rightAlign = false,
  asPath,
}: NavigationProps) => {
  return (
    <Styled.StyledNavigationList data-testid={`navigation`}>
      {navigationItems &&
        navigationItems.map(
          (rootNavItem: NavigationItemProps, index: number) => {
            return (
              <NavSection
                {...rootNavItem}
                rightAlign={rightAlign}
                asPath={asPath}
                data-testid={`nav-item-${index}`}
                key={`nav-item-${index}`}
              />
            );
          },
        )}
    </Styled.StyledNavigationList>
  );
};
