import { Portal } from 'next/dist/client/portal';
import { useToast } from './useToast';
import * as Styled from './ToastManager.styled';
import { Notification } from '../notification/Notification';
import { Icon, IconSizes } from '../../atoms';
import {
  NotificationVariants,
  NotificationFontSizes,
} from '../notification/Notification.types';

type SizeMap = {
  [key in NotificationFontSizes]: IconSizes;
};

const sizeMap: SizeMap = {
  SMALL: 16,
  MEDIUM: 20,
  LARGE: 24,
};

const ToastManager = () => {
  const { items, deleteToast } = useToast(true);
  return (
    <Portal type="div">
      <Styled.List>
        {items.map((item) => (
          <Styled.ToastWrapper key={item.id} removing={item.removing}>
            <Notification
              onChange={() => deleteToast(item.id)}
              notificationStatus={item.intent}
              text={`${item.message}`}
              notificationVariants={NotificationVariants.Toast}
              notificationSizes={item.size}
              icon={
                <Icon
                  icon={item.icon || 'WARNINGCIRCLE'}
                  size={sizeMap[item.size || 'MEDIUM']}
                />
              }
              role="alert"
            />
          </Styled.ToastWrapper>
        ))}
      </Styled.List>
    </Portal>
  );
};

export { ToastManager };
