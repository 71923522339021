import styled from 'styled-components';

export const BottomSheetOverlay = styled.div`
  background-color: #333333a3;
  inset: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.toolkit.zIndex.BACKDROP};
`;

export const BottomSheetContainer = styled.div`
  background-color: ${({ theme }) => theme.toolkit.brand.background.primary};
  border-radius: ${({ theme }) =>
    `${theme.toolkit.borderRadius.M} ${theme.toolkit.borderRadius.M} 0 0`};
  bottom: 0;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.toolkit.zIndex.MODAL};
`;

export const BottomSheetContentWrapper = styled.div`
  max-height: 520px; // 640px - 120px (sticky header and footer)
  overflow: auto;
`;
