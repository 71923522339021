import {
  ImageGridAspectRatio,
  ImageGridImagesProps,
  VisibleThumbnailsCount,
} from '../../atoms/imageGrid/ImageGrid.types';

export enum GalleryAspectRatio {
  RATIO_2_1 = '2/1',
  RATIO_3_2 = '3/2',
  RATIO_16_9 = '16/9',
  RATIO_17_10 = '17/10',
}

export enum GalleryImageObjectFit {
  CONTAIN = 'contain',
  COVER = 'cover',
}

type GalleryCommonProps = {
  children: React.ReactNode | React.ReactNode[];
  imageGridImages?: ImageGridImagesProps[];
  visibleGridImagesCount?: VisibleThumbnailsCount;
  forceMobileView?: boolean;
};

export type GalleryProps = GalleryCommonProps & {
  aspectRatio?: GalleryAspectRatio;
  aspectRatioMobile?: GalleryAspectRatio;
  bottomLeftComponent?: React.ReactNode;
  bottomRightComponent?: React.ReactNode;
  initialIndex?: number;
  isLoading?: boolean;
  loadingSpinner?: React.ReactNode;
  objectFit?: GalleryImageObjectFit;
  /** Optional extra function to call when navigating through the gallery. Index changing is handled internally */
  onChange?: (index: number) => void;
  hasSlideAnimation?: boolean;
  topLeftComponent?: React.ReactNode;
  topRightComponent?: React.ReactNode;
  imageGridAspectRatioHorizontal?: ImageGridAspectRatio;
  hasIndicator?: boolean;
  /** Buttons control displayed only when hovered (true) or always visible (false) */
  hoverInteraction?: boolean;
  imageLoading?: 'eager' | 'lazy';
  dataTracking?: { [key: string]: string };
  fallbackImage?: string;
};

export type GalleryStyledProps = GalleryCommonProps;

export type GalleryImageItemProps = {
  objectFit: GalleryImageObjectFit;
  translateXOffset: number;
  hasSlideAnimation: boolean;
};

export type GalleryImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export type SlotPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export type SvgComponent = {
  src: string;
  alt?: string;
};
