import React, { Component } from 'react';

import { CloseIcon } from '@dsch/icons';
import styled from 'styled-components';

import {
  Button as NewButton,
  ButtonSizes as NewButtonSizes,
  ButtonTypes as NewButtonTypes,
  Spinner as DelayedSpinner,
} from '@dist-property-frontend/toolkit';
import ReactModalAdapter from './components/atoms/Modal';

export type ModalProps = {
  filters: (options?: {
    LocationInputBox: { parentRef: React.RefObject<HTMLDivElement> };
  }) => JSX.Element;
  totalResults: number;
  resetCallback: () => void;
  hiddenFilters: () => JSX.Element;
  renderSortFilter: any;
  onModalOpen?: () => void;
  isLoadingSection?: boolean;
  closeModal: () => void;
  isOpen: boolean;
};

type ModalState = {
  modalRef: React.RefObject<HTMLDivElement>;
  pathName: string;
};

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    background-color: #ffffff;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media only screen and (min-width: 900px) {
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0;
    }
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding-bottom: 128px;

    @media only screen and (min-width: 900px) {
      overflow: hidden;
      background-color: #ffffff;
      width: 384px;
      position: fixed;
      height: 100%;
      right: 0;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
      padding: 0;

      &:focus {
        outline: none;
      }
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;
  @media only screen and (min-width: 900px) {
    padding: 32px 24px;
    margin-bottom: 0;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: #777777;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CloseIconButton = styled.button`
  background: none;
  padding: 0;
  margin-right: 10px;
  margin-left: auto;
  display: block;
  position: relative;
`;

const ScrollArea = styled.div`
  padding: 22px 16px;
  @media only screen and (min-width: 900px) {
    height: calc(100% - 80px);
  }
  width: 100%;
  overflow-y: scroll;

  &:before {
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    opacity: 0.55;
  }
`;

const FilterList = styled.div`
  list-style: none;
  & > * {
    margin-bottom: 24px;
    @media only screen and (min-width: 900px) {
      padding: 0 26px;
    }
  }

  .text-cutter {
    @media only screen and (min-width: 704px) {
      padding-right: 28px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 22px 16px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 600;
  @media only screen and (min-width: 900px) {
    width: 384px;
    padding: 16px 48px;
    left: auto;
  }
`;

const DesktopClearButton = styled.button`
  display: none;
  @media only screen and (min-width: 900px) {
    display: block;
    font-size: 14px;
    background: none;
    border: none;
    color: #4170c4;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    &:after {
      content: '';
      background-color: transparent;
      display: block;
      height: 2px;
      width: 100%;
    }
    &:hover:after {
      background-color: #4170c4;
    }
  }
`;

const MobileClearButton = styled.button`
  display: block;
  padding: 15px 20px;
  font-weight: 600;
  margin: auto;
  background: #f4f4f4;
  color: #333333;
  border-radius: 5px;

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

const SectionDivider = styled.div`
  border-bottom: 1px solid #dddddd;
  margin-bottom: 32px;
  display: none;
  @media only screen and (min-width: 900px) {
    display: block;
  }
`;

const OpacityOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.65;
`;

const CenteredSpinner = styled(DelayedSpinner)`
  position: absolute;
  top: calc(50% - 20px);
  right: calc(50% - 20px);
  width: 40px;
  height: 40px;
`;

const LoadingOverlay = ({ className }: { className?: string }) => (
  <div className={className}>
    <OpacityOverlay />
    <CenteredSpinner />
  </div>
);

const StyledLoading = styled(LoadingOverlay)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000; /* has to be high because county-area is high z-indexed*/
`;

class FiltersSearchModal extends Component<ModalProps, ModalState> {
  state = {
    modalRef: React.createRef() as React.RefObject<HTMLDivElement>,
    pathName: '',
  };

  componentDidMount() {
    this.setState({ pathName: window.location.pathname });
  }

  componentDidUpdate(_prevProps: ModalProps) {
    const pathName = window.location.pathname;
    if (this.state.pathName !== pathName) {
      this.setState({ pathName: window.location.pathname });
      this.props.onModalOpen?.();
    }
  }

  setModalRef = (modalRef: React.RefObject<HTMLDivElement>) => {
    this.setState({ modalRef });
  };

  render() {
    const {
      filters,
      resetCallback,
      totalResults,
      hiddenFilters,
      renderSortFilter,
      isLoadingSection = false,
      isOpen,
      closeModal,
    } = this.props;

    const formattedTotalResults = totalResults.toLocaleString();

    return (
      <StyledModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Filter Search"
        ariaHideApp={false}
        testId="searchFiltersModal"
        shouldCloseOnOverlayClick={true}
        contentRef={this.setModalRef}
      >
        {isLoadingSection ? <StyledLoading /> : null}
        <ScrollArea>
          <Header>
            <DesktopClearButton
              onClick={resetCallback}
              data-testid="modal-clear-filter-button"
            >
              Clear all
            </DesktopClearButton>
            <CloseIconButton onClick={closeModal}>
              <StyledCloseIcon data-testid="close-filters-modal" />
            </CloseIconButton>
          </Header>
          {/* On Daft we include the headers for the sections BUY, RENT, SHARE and NEW HOMES here
           * this needs to be migrated in the future whenever Daft consumes filters from this lib
           */}
          <SectionDivider />

          <FilterList>
            {filters({
              LocationInputBox: {
                parentRef: this.state.modalRef,
              },
            })}
            {hiddenFilters()}
            {renderSortFilter()}
          </FilterList>
          <MobileClearButton
            onClick={resetCallback}
            data-testid="modal-clear-filter-button-mobile"
          >
            Clear Filters
          </MobileClearButton>
        </ScrollArea>

        <Footer>
          <NewButton
            buttonType={NewButtonTypes.PRIMARY}
            buttonSize={NewButtonSizes.SMALL}
            onClick={closeModal}
            fullWidth
            data-testid="filters-modal-show-results-button"
          >
            Show {formattedTotalResults} results
          </NewButton>
        </Footer>
      </StyledModal>
    );
  }
}

export default FiltersSearchModal;
