import styled from 'styled-components';
import { StickyFooterProps } from './StickyFooter.types';

const StickyFooterContainer = styled.footer<{ padding: 'M16' | 'M24' }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ padding, theme }) =>
    `${theme.toolkit.spacing.M16} ${theme.toolkit.spacing[padding]} ${theme.toolkit.spacing.M16} ${theme.toolkit.spacing[padding]}`};
  position: sticky;
  width: 100%;
`;

export const StickyFooter = ({
  content,
  padding = 'M16',
}: StickyFooterProps) => {
  return (
    <StickyFooterContainer padding={padding}>{content}</StickyFooterContainer>
  );
};
