import styled from 'styled-components';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Text,
  TextFontWeight,
  TextSize,
} from '../../atoms';
import { InfoTitle, InfoTitleSize } from '../../molecules';
import { StickyHeaderProps } from './StickyHeader.types';

const StickyHeaderContainer = styled.header<{ padding: 'M16' | 'M24' }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ padding, theme }) =>
    `${theme.toolkit.spacing[padding]} ${theme.toolkit.spacing[padding]} ${theme.toolkit.spacing.S8} ${theme.toolkit.spacing[padding]}`};
  position: sticky;
  width: 100%;
`;

export const StickyHeader = ({
  handleClose,
  headerText,
  padding = 'M16',
}: StickyHeaderProps) => {
  return (
    <StickyHeaderContainer padding={padding}>
      <InfoTitle
        heading={
          <Text
            as="h3"
            size={TextSize.L18}
            fontWeight={TextFontWeight.SEMIBOLD}
          >
            {headerText}
          </Text>
        }
        size={InfoTitleSize.LARGE}
      />
      {!!handleClose && (
        <Button
          buttonSize={ButtonSizes.SMALL}
          buttonType={ButtonTypes.GHOST}
          Icon={<Icon icon="CLOSE" size={20} />}
          onClick={handleClose}
        />
      )}
    </StickyHeaderContainer>
  );
};
