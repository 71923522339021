import React, { Component } from 'react';

import styled from 'styled-components';

import { DropDownRangeFilter } from '../../atoms/DropDownRange/DropDownRangeFilter';
import {
  DropDownRangeSelect,
  DropDownRangeSelectProps,
} from '../../atoms/DropDownRange/DropDownRangeSelect';
import {
  CustomSelect,
  FakeSelect,
  FilterSelectCSS,
  Label,
  MenuCSS,
} from '../../../Styles/SharedFilterStyles';

const Ranges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 288px;
`;

const Text = styled.div`
  font-size: 14px;
  color: #777777;
  margin: 0 12px;
`;

const StyledDropDownRangeSelect = styled(
  DropDownRangeSelect,
)<DropDownRangeSelectProps>`
  ${FilterSelectCSS};
`;

const PopOutWrapper = styled.div`
  position: relative;

  ${CustomSelect} {
    width: 100%;
  }

  select {
    min-width: 105px;
  }
`;

const Menu = styled.div`
  ${MenuCSS}
  ${(props: { showMenu?: boolean }) =>
    props.showMenu &&
    `
      display: block;
    `};
`;

export default class PopOutDropDownRange extends Component<any, any> {
  state = { showMenu: false, displayText: this.props.displayName };
  public menuRef: any;
  public setTextInputRef: any;
  public popOutWrapper: any;
  public popOutWrapperRef: any;

  constructor(props: any) {
    super(props);

    this.menuRef = React.createRef();

    this.setTextInputRef = (element: any) => {
      this.menuRef = element;
    };

    this.popOutWrapper = React.createRef();

    this.popOutWrapperRef = (element: any) => {
      this.popOutWrapper = element;
    };
  }
  showMenu = (event: any) => {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu, { capture: true });
      document.addEventListener('keydown', this.keyCloseMenu, {
        capture: true,
      });
    });
  };

  keyCloseMenu = (event: any) => {
    if (event.key !== 'Tab') return;
    if (!this.state.showMenu || !this.menuRef) return;
    setTimeout(() => {
      if (!this.popOutWrapper.contains(document.activeElement)) {
        this.setState({ showMenu: false }, () => {
          document.removeEventListener('keydown', this.keyCloseMenu);
          document.removeEventListener('click', this.closeMenu);
        });
      }
    }); // need to bump to next cycle for checking the active element
  };

  closeMenu = (event: any) => {
    if (!this.state.showMenu || !this.menuRef) return;
    if (!this.menuRef.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('keydown', this.keyCloseMenu);
        document.removeEventListener('click', this.closeMenu);
      });
    }
  };

  updateDisplayText = (value: string) => {
    this.setState({ displayText: value });
  };

  render() {
    const { displayText, showMenu } = this.state;
    const { displayName, showLabel, id } = this.props;
    return (
      <PopOutWrapper ref={this.popOutWrapperRef}>
        <CustomSelect>
          <FakeSelect onClick={this.showMenu} data-testid={id}>
            {displayText}
          </FakeSelect>
        </CustomSelect>

        <div ref={this.setTextInputRef}>
          <Menu showMenu={showMenu} data-testid={`${id}Menu`}>
            <DropDownRangeFilter
              {...(this.props as any)}
              getDisplayText={this.updateDisplayText}
            >
              {({ getFromProps, getToProps }) => (
                <div>
                  {showLabel && (
                    <Label htmlFor={`${id}From`}>{displayName}</Label>
                  )}
                  <Ranges>
                    <CustomSelect>
                      <StyledDropDownRangeSelect
                        {...getFromProps()}
                        id={`${id}From`}
                      />
                    </CustomSelect>
                    <Text>to</Text>
                    <CustomSelect>
                      <StyledDropDownRangeSelect
                        {...getToProps()}
                        id={`${id}To`}
                      />
                    </CustomSelect>
                  </Ranges>
                </div>
              )}
            </DropDownRangeFilter>
          </Menu>
        </div>
      </PopOutWrapper>
    );
  }
}
