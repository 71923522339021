import React, { Component } from 'react';

type Option = {
  value: string;
  displayName: string;
};

type OnChangeProps = {
  name?: string;
  displayName?: string;
  values: string[];
  searchQueryGroup?: string;
  filterType: string;
  rangeSide: string;
};

export type DropDownRangeSelectProps = {
  name?: string;
  values: Option[];
  id?: string;
  onChange: ({
    name,
    values,
    searchQueryGroup,
    filterType,
    rangeSide,
    displayName,
  }: OnChangeProps) => void;
  className?: string;
  disabled?: boolean;
  currentValue?: { values: string[] };
  searchQueryGroup?: string;
  rangeSide: string;
};

class DropDownRangeSelect extends Component<
  DropDownRangeSelectProps,
  { value: string }
> {
  getStateForCurrentValue() {
    const { currentValue, values } = this.props;
    const firstItemInList = values.length ? values[0].value : '';
    return {
      value: currentValue?.values?.[0] ?? firstItemInList,
    };
  }

  constructor(props: DropDownRangeSelectProps) {
    super(props);
    this.state = this.getStateForCurrentValue();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState(this.getStateForCurrentValue());
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { onChange, searchQueryGroup, name, rangeSide } = this.props;
    const selectedItem = this.props.values.filter(
      (item: any) => item.value === value,
    );
    onChange({
      name,
      rangeSide,
      searchQueryGroup,
      displayName: selectedItem[0].displayName,
      values: [value],
      filterType: 'DropDownRange',
    });
    this.setState({ value });
  };

  render() {
    const {
      name,
      className,
      disabled = false,
      values,
      rangeSide,
      id,
    } = this.props;
    return (
      <select
        name={name}
        className={className}
        disabled={disabled}
        value={this.state.value}
        onChange={this.handleChange}
        id={id ?? name}
        data-testid={name}
        aria-label={name}
      >
        {values.map(({ value, displayName }: Option) => (
          <option key={`${rangeSide}-${value}`} value={value}>
            {displayName}
          </option>
        ))}
      </select>
    );
  }
}

export { DropDownRangeSelect };
