import { useState, cloneElement, useRef, useEffect, useCallback } from 'react';
import {
  TBoundary,
  TTooltipHook,
  TTooltipPositions,
  TTooltipSizes,
} from './Tooltip.types';

export const useTooltip = (props: TTooltipHook) => {
  const { size = TTooltipSizes.MEDIUM, delay, children } = props;
  const tooltipWidthWeb = 360;
  let timeout: NodeJS.Timeout;

  const targetRef = useRef<HTMLParagraphElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const clonedChildren = cloneElement(children, { ref: targetRef });

  const [active, setActive] = useState(false);
  const [badgeWidth, setBadgeWidth] = useState<number>(0);
  const isBadge = size === TTooltipSizes.BADGE;

  // Function to calulate the dynamic size of the badge cause the text length could change
  const handleBadgeResize = useCallback(async () => {
    if (tooltipRef?.current) {
      const { width } = tooltipRef.current.getBoundingClientRect();
      setBadgeWidth(width);
    }
  }, [tooltipRef]);

  useEffect(() => {
    if (isBadge && tooltipRef?.current) {
      handleBadgeResize();
    }
  }, [active, handleBadgeResize, tooltipRef, isBadge]);

  const [boundary, setBoundary] = useState<TBoundary>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
  });

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 300);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const handleCloseClick = () => {
    hideTip();
  };

  const waitForRender = () => {
    return new Promise((resolve) => {
      window.requestAnimationFrame(resolve);
    });
  };

  /**
   * Function to set the boundary value of the tooltip
   *
   * top -  screen top --> target element top border
   * bottom - screen bottom --> target element bottom border
   * left - screen left --> target element center
   * right - target element center <-- screen right
   * width - Width of the target element
   * height - Height of the target element
   */
  const handleResize = () => {
    if (targetRef.current) {
      const { top, bottom, left, right, width, height } =
        targetRef.current.getBoundingClientRect();
      setBoundary({
        top: top,
        bottom: window.innerHeight - bottom,
        left: left + width / 2,
        right: window.innerWidth - right + width / 2,
        width: width,
        height: height,
      });
    }
  };

  useEffect(() => {
    const handleInitialResize = async () => {
      await waitForRender();
      handleResize();
    };

    window.addEventListener('resize', handleResize);

    handleInitialResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [targetRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        !targetRef.current?.contains(event.target as Node)
      ) {
        hideTip();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  /**
   * Function to calulate the tooltip position type using boundary value and tooltip width
   * isCentered - If the tooltip could be position in the center of target without overflowing
   * isStart - If the tooptip will overflow the screen from the left when positioned at the center
   * isEnd - If the tooptip will overflow the screen from the right when positioned at the center
   */
  const getTipPosition = (boundary: TBoundary) => {
    const tooltipWidth = isBadge ? badgeWidth : tooltipWidthWeb;
    
    if (typeof window === 'undefined' || window.innerWidth < tooltipWidth) {
      return TTooltipPositions.BOTTOM;
    }

    const isCentered =
      boundary.left > tooltipWidth / 2 && boundary.right > tooltipWidth / 2;
    const isStart =
      boundary.left < tooltipWidth / 2 &&
      window.innerWidth - boundary.left + boundary.width / 2 > tooltipWidth;
    const isEnd =
      boundary.right < tooltipWidth / 2 &&
      window.innerWidth - boundary.right + boundary.width / 2 > tooltipWidth;

    if (isCentered) {
      return TTooltipPositions.BOTTOM_CENTER;
    } else if (isStart) {
      return TTooltipPositions.BOTTOM_START;
    } else if (isEnd) {
      return TTooltipPositions.BOTTOM_END;
    } else {
      return TTooltipPositions.BOTTOM;
    }
  };

  return {
    active,
    boundary,
    clonedChildren,
    tooltipRef,
    showTip,
    hideTip,
    handleCloseClick,
    getTipPosition,
    badgeWidth,
  };
};
