import React from 'react';

import { CheckboxProps } from '@dsch/form-kit';
import styled from 'styled-components';

export type AdditionalProps = CheckboxProps & {
  checkboxText?: string;
};

type StyledProps = {
  isChecked?: boolean;
};

const StyledItem = styled.button<StyledProps>`
  touch-action: manipulation;
  cursor: pointer;
  background-color: #fffefe;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 100%;
  height: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: -2px;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ isChecked }) => isChecked && `border: 2px solid #333333;`};
`;

const MultiSelectItem = ({ checked, checkboxText }: AdditionalProps) => (
  <StyledItem
    onClick={(event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.currentTarget.focus();
    }}
    isChecked={checked}
  >
    {checkboxText}
  </StyledItem>
);

export { MultiSelectItem };
