/*
 *
 * The useToast hook is a global toast notification system for managing and displaying toast messages in a * React application. It allows components to add, delete, and listen to changes in toast notifications.
 *
 */
import { useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import {
  IconName,
  NotificationStatus,
  NotificationFontSizes,
} from '@dist-property-frontend/types';
export type Intent = NotificationStatus; // A type alias for NotificationStatus, representing the purpose or severity of the notification (e.g., success, error, warning).

type Toast = {
  id: string; // A unique identifier for the toast.
  message: string; // The message to display in the toast.
  intent?: Intent; // Defines the purpose or style of the toast (optional).
  size?: NotificationFontSizes; // An optional size for the toast message.
  icon?: IconName; //An optional icon to display alongside the toast message.
  removing?: boolean; // A flag indicating whether the toast is being removed (used for animations).
};
type ToastConfig = {
  intent?: Intent;
  ttl?: number; // Time-to-live in milliseconds. The toast is automatically removed after this duration.
  icon?: IconName; // Optional icon to display with the toast.
  size?: NotificationFontSizes; // Optional size for the toast message.
};
type Callback = React.Dispatch<React.SetStateAction<Toast[]>>; // A callback function that updates the toast state.

// Global state to be shared across all instances of this hook
let items: Toast[] = [];
const observers: { [id: number]: Callback } = {};
let handlerId = 0;

// Notifies all registered observers of the current state of toast notifications.
const updateObservers = (items: Toast[]) => {
  for (const handler of Object.values(observers)) {
    handler([...items]);
  }
};

// Registers an observer to listen to changes in the global toast state.
const registerObserver = (id: number, handler: Callback) => {
  observers[id] = handler;
};
// Removes an observer, stopping its updates when the component unmounts.
const deregisterObserver = (id: number) => {
  delete observers[id];
};

// Adds a new toast notification to the global state.
const addToast = (message: string, config: ToastConfig = {}) => {
  const id = uuidv4();
  const {
    ttl,
    intent = NotificationStatus.SUCCESS,
    size = NotificationFontSizes.MEDIUM,
    icon,
  } = config;
  // Add new toast to start of the list
  items.unshift({
    id,
    message,
    intent,
    icon,
    size,
  });

  // Remove if a ttl was set
  if (ttl) {
    setTimeout(() => {
      deleteToast(id);
    }, ttl);
  }

  // Let observers know something has changed
  updateObservers(items);

  return id;
};

// Removes a toast notification by its unique identifier.
const deleteToast = (id: string) => {
  // Mark the toast as "removing" before actual removal
  items = items.map((item) =>
    item.id === id ? { ...item, removing: true } : item,
  );
  updateObservers(items);

  // Delay actual removal to match the animation duration
  setTimeout(() => {
    items = items.filter((item) => item.id !== id);
    updateObservers(items);
  }, 300); // Adjust duration to match your CSS animation
};

/**
 *
 * @param listen, get updated state when toasts are added or removed
 */
export const useToast = (listen?: boolean) => {
  const id = useMemo(() => handlerId++, []);
  const [itemsState, setItemsState] = useState(items);

  useEffect(() => {
    if (listen) {
      registerObserver(id, setItemsState);
      return () => {
        deregisterObserver(id);
      };
    }
  }, [listen, id]);

  return { fireToast: addToast, deleteToast, items: itemsState };
};
