import type { AnchorHTMLAttributes } from 'react';

export enum Type {
  STANDALONE = 'STANDALONE',
  INLINE = 'INLINE',
}

export enum Style {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum State {
  active = 'active',
  disabled = 'disabled',
  default = 'default',
  hover = 'hover',
  focus = 'focus',
}

export enum Weight {
  REGULAR = 'REGULAR',
  SEMIBOLD = 'SEMIBOLD',
  BOLD = 'BOLD',
}

export type LinkContentWrapperStyle = {
  iconLeading: boolean | undefined;
  iconSize: number;
  size: Size;
};

export type LinkStyleProps = {
  type: Type;
  linkStyle: Style;
  weight: Weight;
  state?: State;
  underlined?: boolean | undefined;
};

export type LinkProps = {
  children: string | (string | JSX.Element)[];
  href?: string;
  weight?: Weight;
  size?: Size;
  type?: Type;
  style?: Style;
  underlined?: boolean | undefined;
  testId?: string;
  iconLeading?: boolean | undefined;
  openNewTab?: boolean;
  callback?: (e: React.MouseEventHandler<HTMLAnchorElement>) => void | undefined;
  dataTracking?: string;
}& AnchorHTMLAttributes<HTMLAnchorElement>;
