import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { FilterRequestAPI } from '../../../FilterTypes';

import { filterCurrency, formatCurrency } from './utils';
import { TextInput } from '../../atoms/TextInput/TextInput';

const PAGE_BREAK_POINT = '704px';

export type TermsFilterProps = {
  name: string;
  searchQueryGroup: string;
  showLabel?: boolean;
  currentValue?: any;
  displayName: string;
  id: number;
  inputType: string;
  onChange: (searchRequestObject: FilterRequestAPI) => void;
  onBlur?: () => void;
  fakeDisabled?: boolean;
  disabled?: boolean;
  options?: {
    shouldShowButton?: boolean;
    displayHint?: string;
    prefix?: string;
    suffix?: string;
  };
};

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const StyledPrefix = styled.span`
  position: absolute;
  top: calc(50% - 9px);
  left: 16px;
  padding-right: 16px;
  font-size: 16px;
  color: #333333;

  @media only screen and (min-width: ${PAGE_BREAK_POINT}) {
    font-size: 14px;
    top: calc(50% - 7px);
  }

  @media print {
    font-size: 12px;
  }
`;

const StyledSuffix = styled.span`
  position: absolute;
  top: calc(50% - 9px);
  right: 16px;
  padding-right: 16px;
  font-size: 16px;
  color: #333333;

  @media only screen and (min-width: ${PAGE_BREAK_POINT}) {
    font-size: 14px;
    top: calc(50% - 7px);
  }
  @media print {
    font-size: 12px;
  }
`;

const SearchButton = styled.button`
  box-sizing: border-box;
  min-width: auto;
  border: #dddddd 1px solid;
  position: absolute;
  top: 0px;
  padding: 4px 4px;
  right: 8px;
  visibility: hidden;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  width: 46px;
  margin-top: 4px;
  height: 32px;
  &:focus,
  &:hover {
    outline: 0;
    border: 1px solid #bbbbbb;
    visibility: visible;
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT}) {
    font-size: 14px;
    padding: 4px 6px;
    right: 16px;
  }
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px !important;
`;

export const StyledInput = styled.input<{ readOnly?: boolean }>`
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border-radius: 5px;
  border: #bbbbbb 1px solid;
  padding: 8px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  ~ ${SearchButton} {
    visibility: hidden;
  }
  padding-left: ${(props: any) => (props.prefix ? '32px' : '8px')};
  &:focus,
  &:hover {
    border: 1px solid #bbbbbb;
  }
  &:focus {
    ~ ${SearchButton} {
      visibility: visible;
    }
    border: 0;
    margin-top: -12px;
    top: 6px;
    position: relative;
    height: 48px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  ::-ms-clear {
    display: none;
  }
  ::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }

  ${({ theme, readOnly }) =>
    readOnly &&
    `
    background: ${theme.color.GREY_LIGHTEST};
    pointer-events: none;
  `}

  @media only screen and (min-width: ${PAGE_BREAK_POINT}) {
    font-size: 14px;
  }

  @media print {
    font-size: 12px;
  }
`;

export const TermsFilter = (props: TermsFilterProps) => {
  const {
    showLabel,
    searchQueryGroup,
    name,
    displayName,
    id,
    options,
    inputType,
    fakeDisabled,
    disabled,
  } = props;
  const [searchFilterObject, setSearchFilterObject] = useState<any>(null);
  const [lastValue, setLastValue] = useState(props.currentValue || '');
  const [currentValue, setCurrentValue] = useState(props.currentValue || '');
  const [isFocused, setIsFocused] = useState(false);

  const sendProps = {
    searchQueryGroup,
    name,
    currentValue,
    id: String(id),
    onChange: onChange,
  };

  const placeholder = options?.displayHint ? options.displayHint : '';

  useEffect(() => {
    inputType === 'currency' &&
      currentValue &&
      setCurrentValue(formatCurrency(`${currentValue}`));
  }, []);

  useEffect(() => {
    setLastValue(props.currentValue);
    setCurrentValue(props.currentValue);
  }, [props.currentValue]);

  useEffect(() => {
    if (inputType !== 'currency') return;
    const value =
      isFocused && currentValue
        ? filterCurrency(`${currentValue}`)
        : formatCurrency(`${currentValue}`);
    setCurrentValue(value);
  }, [isFocused]);

  useEffect(() => {
    disabled && setCurrentValue('');
  }, [disabled]);

  const trigger = async () => {
    const newSearchFilterObject: any = { ...searchFilterObject };
    const currentValue = newSearchFilterObject?.currentValue?.trim();

    if (newSearchFilterObject && lastValue !== currentValue) {
      props.onChange(newSearchFilterObject);
      setLastValue(currentValue);
    }
  };

  function onChange(searchFilterObject: FilterRequestAPI) {
    searchFilterObject.currentValue =
      inputType === 'currency'
        ? filterCurrency(searchFilterObject.currentValue)
        : searchFilterObject.currentValue;
    setSearchFilterObject(searchFilterObject);
    setCurrentValue(searchFilterObject.currentValue);
    setIsFocused(true);
  }

  const onBlur = async () => {
    props.onBlur && props.onBlur();
    await trigger();
    setIsFocused(false);
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLTextAreaElement;
      // blur executes api call
      target.blur();
    }
  };

  const getInputType = (inputType: string | undefined): string => {
    if (inputType === 'currency') {
      return 'text';
    }

    if (inputType) {
      return inputType;
    }

    return 'search';
  };
  return (
    <>
      {showLabel && <Label htmlFor={`keyword${id}`}>{displayName}</Label>}
      <TextInput {...sendProps}>
        {({ onChange }) => (
          <InputWrapper>
            {options?.prefix && (
              <StyledPrefix data-testid="terms-filter-prefix">
                {options.prefix}
              </StyledPrefix>
            )}
            <StyledInput
              prefix={options?.prefix}
              data-testid="terms-input-text"
              id={`keyword${id}`}
              onBlur={onBlur}
              placeholder={placeholder}
              onChange={onChange}
              onMouseDown={onChange}
              value={currentValue || ''}
              type={getInputType(inputType)}
              onKeyDown={onKeyDown}
              autoComplete="new-password"
              aria-label={name}
              name={name}
              readOnly={fakeDisabled || disabled}
            />
            {options?.suffix && (
              <StyledSuffix data-testid="terms-filter-suffix">
                {options.suffix}
              </StyledSuffix>
            )}
            {options?.shouldShowButton && (
              <SearchButton data-testid="terms-search-button">
                Filter
              </SearchButton>
            )}
          </InputWrapper>
        )}
      </TextInput>
    </>
  );
};
