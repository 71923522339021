import styled from 'styled-components';

export const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
`;

export const PaginationList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const PaginationItem = styled.li`
  display: flex;
  height: 40px;
  a {
    &:focus {
      outline: 4px solid ${({ theme }) => theme.toolkit.colours.GREY.LIGHT};
    }
    span {
      height: 20px;
    }
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};
    display: flex;
    padding: ${({ theme }) => theme.toolkit.spacing.S8}
      ${({ theme }) => theme.toolkit.spacing.M16};
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    background-color: #fff;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.toolkit.colours.GREY.DARKER};
    transition: all 0.1s ease-in-out;
    outline: 1px solid ${({ theme }) => theme.toolkit.colours.GREY.LIGHT};
    :hover {
      outline: 1px solid ${({ theme }) => theme.toolkit.colours.GREY.DARK};
      color: 1px solid ${({ theme }) => theme.toolkit.colours.GREY.DARKER};
      background-color: transparent;
    }
    &[aria-current='true'] {
      outline: 2px solid ${({ theme }) => theme.toolkit.colours.GREY.DARKER};
      background-color: #fff;
    }
  }
`;

export const Ellipsis = styled.span`
  background: none;
  display: flex;
  align-items: center;
`;
