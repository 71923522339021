import { SECTIONS } from '../../Filters/FilterTypes';

export enum VT_SEARCH_URLS {
  PROPERTY_PRICE_REGISTER = 'valuation-tool-property-price-register',
  RESIDENTIAL_RENT = 'valuation-tool-residential-rent',
  RESIDENTIAL_SALES = 'valuation-tool-residential-sales',
  COMMERCIAL = 'valuation-tool-commercial',
}

export enum SEARCH_URLS {
  BUY = 'property-for-sale',
  RENT = 'property-for-rent',
  SHARE = 'sharing',
  SOLD = 'sold-properties',
  NEW_HOMES = 'new-homes-for-sale',
  COMMERCIAL_RENT = 'commercial-properties-for-rent',
  COMMERCIAL_BUY = 'commercial-properties-for-sale',
  STUDENT_ACCOMMODATION_RENT = 'student-accommodation-for-rent',
  STUDENT_ACCOMMODATION_SHARE = 'student-accommodation-to-share',
  PARKING_BUY = 'parking-spaces-for-sale',
  PARKING_RENT = 'parking-spaces-for-rent',
  HOLIDAY_HOMES = 'holiday-homes',
}

export enum AGENT_OFFERS {
  RESIDENTIAL_SALES = 'offers/residential-for-sale',
  COMMERCIAL_SALES = 'offers/commercial-for-sale',
}
export const buildRouteBaseQuery = (routeName: string, query: any): any => {
  switch (routeName) {
    case SEARCH_URLS.BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.RENT,
        },
        h1AdType: 'to Rent',
      };
    case SEARCH_URLS.SHARE:
      return {
        query: {
          ...query,
          section: SECTIONS.SHARE,
        },
        h1AdType: 'to Share',
      };
    case SEARCH_URLS.SOLD:
      return {
        query: {
          ...query,
          section: SECTIONS.SOLD,
        },
        h1AdType: 'Sold Properties',
      };
    case SEARCH_URLS.NEW_HOMES:
      return {
        query: {
          ...query,
          section: SECTIONS.NEW_HOMES,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.COMMERCIAL_BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.COMMERCIAL_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_RENT,
        },
        h1AdType: 'to Rent',
      };
    case SEARCH_URLS.STUDENT_ACCOMMODATION_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.STUDENT_ACCOMMODATION_RENT,
        },
        h1AdType: 'for Rent',
      };
    case SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE:
      return {
        query: {
          ...query,
          section: SECTIONS.STUDENT_ACCOMMODATION_SHARE,
        },
        h1AdType: 'to Share',
      };
    case SEARCH_URLS.PARKING_BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.PARKING_BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.PARKING_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.PARKING_RENT,
        },
        h1AdType: 'to Rent',
      };
    case SEARCH_URLS.HOLIDAY_HOMES:
      return {
        query: {
          ...query,
          section: SECTIONS.HOLIDAY_HOMES,
        },
        h1AdType: '',
      };
    case VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_PROPERTY_PRICE_REGISTER,
        },
      };
    case VT_SEARCH_URLS.RESIDENTIAL_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_RESIDENTIAL_RENT,
        },
      };
    case VT_SEARCH_URLS.RESIDENTIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_RESIDENTIAL_SALES,
        },
      };
    case VT_SEARCH_URLS.COMMERCIAL:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_COMMERCIAL,
        },
      };
    case AGENT_OFFERS.RESIDENTIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.BUY,
        },
      };
    case AGENT_OFFERS.COMMERCIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_BUY,
        },
      };
    default:
      return { query };
  }
};

export default buildRouteBaseQuery;

export const REMOVE_FROM_URL = [
  'section',
  'h1AdType',
  'agent_id',
  'legacy_agent_id',
];
