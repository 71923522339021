import { useEffect, useState } from 'react';

// ********** This will be moved in another PR **********
// Check if the current device is a mobile device
export const useCheckIsMobile = (breakpoint = 768) => {
  const [isMobileState, setIsMobileState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleResize = () => {
      setIsMobileState(window.innerWidth <= breakpoint);
    };

    // Check the initial window size on component mount
    handleResize();
    setIsLoading(false);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return { isMobileState, isLoading };
};
