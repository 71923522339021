import styled from 'styled-components';
import { THeaderStyled } from './Header.types';

export const HeaderWrapper = styled.header<THeaderStyled>`
  background: ${({ theme, bgColor }) =>
    theme.toolkit.brand.background[
      bgColor as keyof typeof theme.toolkit.brand.background
    ]};

  height: ${({ theme }) => theme.toolkit.spacing.XL64};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.toolkit.spacing.M16};
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    height: ${({ theme }) => theme.toolkit.spacing.XL72};
    padding: 0 ${({ theme }) => theme.toolkit.spacing.M32};
  }
`;

export const InnerWrapper = styled.div`
  background: #ffffff;
  overflow-y: auto;
  width: 18.75rem;
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  &:before {
    display: block;
    background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    height: 0.0625rem;
    width: 100%;
    content: '';
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.M32};
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.small} {
    left: auto;
    width: 320px;
  }
  padding-top: 72px;
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    &:before {
      display: none;
    }
    overflow-y: visible;
    position: relative;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    padding-top: 0;
    display: flex;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${({ theme }) => theme.toolkit.spacing.M32};
`;

export const BurgerWrapper = styled.div`
  z-index: 11;
  position: absolute;
  right: ${({ theme }) => theme.toolkit.spacing.M16};
  top: ${({ theme }) => theme.toolkit.spacing.M16};
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    display: none;
  }
`;

export const MainNav = styled.nav<THeaderStyled>`
  list-style-type: none;
  visibility: hidden;
  position: fixed;
  right: 0;
  z-index: 10;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(68, 68, 68, 0.48);

  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    visibility: visible;
    background: transparent;

    .utiliy {
      margin-left: auto;
    }
  }

  &.show {
    visibility: visible;
  }
  &.hide {
    visibility: hidden;
    @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
      visibility: visible;
    }
  }
`;
