import type { ReactElement, ReactNode } from 'react';

import { render, RenderOptions } from '@testing-library/react';

import { GlobalStyles } from '../styles/theme.styled';
import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from '@dist-property-frontend/configs';

interface TestWrapperProps {
  children: ReactNode;
}

const ThemeWrapper = ({ children }: TestWrapperProps) => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>,
) => render(ui, { wrapper: ThemeWrapper, ...options });

// re-export everything
export * from '@testing-library/react';
export * from '@testing-library/user-event';

// override render method
export { customRender as render };
