import {
  BottomSheetContainer,
  BottomSheetContentWrapper,
  BottomSheetOverlay,
} from './BottomSheet.styled';
import { BottomSheetProps } from './BottomSheet.types';

const BottomSheet = ({
  contentElement,
  contentElementRef,
  stickyFooter,
  stickyHeader,
}: BottomSheetProps) => {
  return (
    <>
      <BottomSheetOverlay />
      <BottomSheetContainer ref={contentElementRef}>
        {stickyHeader}
        <BottomSheetContentWrapper>{contentElement}</BottomSheetContentWrapper>
        {stickyFooter}
      </BottomSheetContainer>
    </>
  );
};

export default BottomSheet;
