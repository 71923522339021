// TODO
// add checkbox, stepper and radio
// add helper message
import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Styled from './FormControl.styled';
import {
  ElementProps,
  FormControlProps,
  FormElements,
} from './FormControl.types';

import Label from '../../atoms/label/Label';
import Input from '../../atoms/input/Input';
import Select from '../../atoms/select/Select';
import Textarea from '../../atoms/textarea/Textarea';
import Checkbox from '../../molecules/checkbox/Checkbox';
import DateTimePicker from '../../molecules/dateTimePicker/DefaultDateTimePicker/DateTimePicker';
import { Stepper } from '../../molecules/stepper/Stepper';
import { AddressSearch } from '../../molecules/addressSearch/AddressSearch';

import { TextareaProps } from '../../atoms/textarea/Textarea.types';
import { TSelect } from '../../atoms/select/Select.types';
import { TLabel } from '../../atoms/label/Label.types';
import { TInput } from '../../atoms/input/Inputs.types';
import { Error } from '../../atoms/error/Error';
import { Helper } from '../../atoms/helper/Helper';
import { TCheckbox } from '../../molecules/checkbox/Checkbox.types';
import { TBaseDateTimePicker } from '../../molecules/dateTimePicker/DateTimePicker.types';
import { StepperProps } from '../../molecules/stepper/Stepper.types';
import { Space } from '../../atoms';

const getFormControlChildren = (element: FormElements, children: ReactNode) => {
  return React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === element,
  );
};

export const FormControl = ({
  id = uuidv4(),
  children,
  disabled = false,
  required = false,
  showNotification = false,
  notificationMessage = 'Field required',
  leading,
  trailing,
  helperMessage,
  dataTracking,
  ...rest
}: FormControlProps) => {
  const label = getFormControlChildren(Label, children);
  const input = getFormControlChildren(Input, children);
  const textarea = getFormControlChildren(Textarea, children);
  const select = getFormControlChildren(Select, children);
  const checkbox = getFormControlChildren(Checkbox, children);
  const dateTimePicker = getFormControlChildren(DateTimePicker, children);
  const stepper = getFormControlChildren(Stepper, children);
  const addressSearch = getFormControlChildren(AddressSearch, children);

  const hasError = showNotification;

  const labelWithProps =
    React.isValidElement(label) &&
    React.cloneElement(label as ElementProps<TLabel>, {
      htmlFor: id,
      required: required,
    });

  const textareaWithProps =
    React.isValidElement(textarea) &&
    React.cloneElement(textarea as ElementProps<TextareaProps>, {
      id,
      required,
      disabled,
      hasError,
    });
  const inputWithProps =
    React.isValidElement(input) &&
    React.cloneElement(input as ElementProps<TInput>, {
      id,
      required,
      disabled,
      hasError,
    });

  const checkboxWithProps =
    React.isValidElement(checkbox) &&
    React.cloneElement(checkbox as ElementProps<TCheckbox>, {
      id,
      required,
      disabled,
    });

  const dateTimePickerWithProps =
    React.isValidElement(dateTimePicker) &&
    React.cloneElement(dateTimePicker as ElementProps<TBaseDateTimePicker>, {
      id,
      required,
      disabled,
      hasError,
    });

  const selectWithProps =
    React.isValidElement(select) &&
    React.cloneElement(select as ElementProps<TSelect>, {
      id,
      required,
      disabled,
      hasError: hasError,
    });

  const stepperWithProps =
    React.isValidElement(stepper) &&
    React.cloneElement(stepper as ElementProps<StepperProps>, {
      hasError: hasError,
    });

  const addressSearchWithProps =
    React.isValidElement(addressSearch) &&
    React.cloneElement(addressSearch as ElementProps<StepperProps>, {});

  const formElements = [
    inputWithProps,
    textareaWithProps,
    selectWithProps,
    checkboxWithProps,
    dateTimePickerWithProps,
    stepperWithProps,
    addressSearchWithProps,
  ];

  const isValid = formElements.filter(Boolean).length === 1;
  const isInline = !!checkboxWithProps;
  const formControl = formElements.find(Boolean);

  if (!isValid)
    console.error(
      'You must pass a valid form element ex: Select, Input, Textarea, Checkbox',
    );

  const renderFormElement = (
    <Styled.Wrapper>
      {leading && leading}
      {isValid && (
        <Styled.Control isInline={!!leading || !!trailing}>
          {formControl}
        </Styled.Control>
      )}
      {trailing && trailing}
    </Styled.Wrapper>
  );

  return (
    <Styled.FormControl disabled={disabled} isInline={isInline} {...rest}>
      <>
        {isInline && renderFormElement}
        {labelWithProps && (
          <Styled.FormLabel>{labelWithProps}</Styled.FormLabel>
        )}
        {!isInline && renderFormElement}
        <div>
          {!showNotification && helperMessage && (
            <>
              <Space space="S8" />
              <Helper>{helperMessage}</Helper>
            </>
          )}
          {showNotification && (
            <>
              <Space space="S8" />
              <Error id="" data-tracking={`${dataTracking}_error`}>
                {notificationMessage}
              </Error>
            </>
          )}
        </div>
      </>
    </Styled.FormControl>
  );
};

export default FormControl;
