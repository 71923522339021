import React from 'react';

import BlockySingleSelect from '../../molecules/SingleSelect/BlockySingleSelect';
import DefaultSingleSelect from '../../molecules/SingleSelect/DefaultSingleSelect';

declare type Option = {
  value: string;
  displayName: string;
};

export interface VariableSingleSelectProps {
  variant?: string;
  name?: string;
  values: Option[];
  id?: string;
  onChange: any;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  currentValue?: {
    values: string[];
  };
  showLabel?: boolean;
  searchQueryGroup?: string;
  dataTestId?: string;
  filterType: {
    name: string;
  };
  displayName: string;
  hasActiveBorder?: boolean;
}

const VariableSingleSelect = (props: VariableSingleSelectProps) => {
  const { variant, ...rest } = props;

  if (variant === 'DAFT_BLOCKY') {
    return <BlockySingleSelect {...rest} />;
  } else {
    return <DefaultSingleSelect {...rest} />;
  }
};

export default VariableSingleSelect;
