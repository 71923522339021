import styled from 'styled-components';

export const MenuContainer = styled.div<{
  alignment: 'LEFT' | 'RIGHT';
  maxHeight: string;
  maxWidth?: string;
  width: string;
}>`
  background-color: ${({ theme }) => theme.toolkit.brand.background.primary};
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  box-shadow: 0px 4px 16px 0px #33333333;
  ${({ alignment }) => (alignment === 'LEFT' ? 'left: 0;' : 'right: 0;')}
  overflow: hidden;
  position: absolute;
  top: ${({ theme }) =>
    `calc(100% + ${theme.toolkit.spacing.S8})`}; /* Positions below the trigger */
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth || '20rem'};
  max-height: ${({ maxHeight }) => maxHeight};
  z-index: ${({ theme }) => theme.toolkit.zIndex.POPOVER};
`;

export const ContentElementWrapper = styled.div<{
  maxHeight: string;
}>`
  max-height: ${({ maxHeight }) => `calc(${maxHeight} - 120px)`};
  overflow: auto;
`;
